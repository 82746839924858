import { useState } from 'react';
import { IUser, User } from 'types/user';
import { useStorage } from 'hooks/authHooks/useStorage';
import { useAuthContext } from 'context/auth/authContext';
import { useModalContext } from 'context/modal/modalContext';
import { routing } from 'constants/routing';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUserData as setUserDataRedux } from 'redux/actions/userDataAction';


export const useUser = () => {
  const { setUser } = useAuthContext();
  const dispatch = useDispatch();
  const { closeModal } = useModalContext();
  const [user, setUserInfo] = useState<IUser | null>(null);
  const { setUserData } = useStorage();
  const navigate = useNavigate();

  const addUser = (userData: User | null) => {
    if (userData) {
      setUser(userData);
      setUserData(userData);
      setUserInfo(userData?.user ?? {});
    }
  };

  const removeUser = () => {
    setUser(null);
    setUserInfo(null);
    setUserData(null);
    closeModal();
    dispatch(setUserDataRedux(null));
    return navigate(routing.home)
  };

  return { user, addUser, removeUser };
};
