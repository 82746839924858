import {
  AlertColor,
  Box,
  Button,
  FormControl,
  Input,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import cx from 'classnames';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { useDispatch, useSelector } from 'react-redux';
import common from 'styles/colors.module.scss';
import { FIELD_NAME_USER_INFO, IUserInfo } from 'types/user';
import { isValidValueForUserInfo } from 'utils/helpers/inputHelper';
import { useTranslation } from 'react-i18next';
import { useUserData } from 'hooks/user/useUserData';
import { updateUserDataForFields } from 'redux/actions/userDataAction';
import Result from 'components/alert/alert';
import LoaderWithBg from 'components/loader/loader';
import { RESULT } from 'types/common';
import InputMask from 'react-input-mask';
import styles from './userInfo.module.scss';

const field = {
  [FIELD_NAME_USER_INFO.BIRTHDAY_AT]: true,
  [FIELD_NAME_USER_INFO.EMAIL]: true,
  [FIELD_NAME_USER_INFO.PHONE]: true,
  [FIELD_NAME_USER_INFO.LAST_NAME]: true,
  [FIELD_NAME_USER_INFO.FIRST_NAME]: true,
};

function UserInfo() {
  const { t: valitdationT } = useTranslation('validation');
  const { t: profileT } = useTranslation('profile');
  const { t: commonT } = useTranslation('common');
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<IUserInfo>({});
  const [apiError, setApiError] = useState<string>('');
  const [type, setType] = useState<AlertColor>('success');
  const [validField, setValidField] = useState(field);
  const { isLoading, changeUserData, resetError, error } = useUserData();
  const userData = useSelector<any>((state) => state.userData) as any;

  const handleOnClose = () => {
    resetError();
    setApiError('');
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, name: string) => {
    const { value } = e.target;
    setFormData((formData) => ({ ...formData, [name]: value }));
    setValidField((validField) => ({
      ...validField,
      [name]: isValidValueForUserInfo(value, name),
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const result = await changeUserData(userData.id, formData);
    if (result?.status === 200) {
      dispatch(updateUserDataForFields(formData));
      setApiError(commonT(RESULT.SUCCESS));
      setType(RESULT.SUCCESS);
    }
  };

  const handleOnBlurPhone = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    const validFields = {
      [FIELD_NAME_USER_INFO.PHONE]: isValidValueForUserInfo(
        value,
        FIELD_NAME_USER_INFO.PHONE,
      ) as boolean,
    };

    setValidField((fields) => ({
      ...fields,
      ...validFields,
    }));
  };

  const handleOnChangeDatePicker = (e: Dayjs | null) => {
    if (isValidValueForUserInfo(e, FIELD_NAME_USER_INFO.BIRTHDAY_AT)) {
      setValidField((validField) => ({ ...validField, [FIELD_NAME_USER_INFO.BIRTHDAY_AT]: true }));
    } else {
      setValidField((validField) => ({ ...validField, [FIELD_NAME_USER_INFO.BIRTHDAY_AT]: false }));
    }
    setFormData((formData) => ({ ...formData, birthdayAt: e }));
  };

  return (
    <>
      {isLoading ? <LoaderWithBg isOpen={isLoading} /> : null}
      <Box className={styles.main_content}>
        <Box className={styles.main_title}>{profileT('info')}</Box>
        <Box component="form" onSubmit={handleSubmit}>
          <Box className={styles.input_content}>
            <FormControl
              className={cx(styles.form_control, {
                [styles.is_error]: !validField[FIELD_NAME_USER_INFO.EMAIL],
              })}
            >
              <InputLabel
                shrink
                htmlFor="email-input"
                className={cx({ [styles.is_disabled]: !!userData.isEmailConfirmed })}
              >
                {validField[FIELD_NAME_USER_INFO.EMAIL] ? (
                  profileT('email')
                ) : (
                  <Typography sx={{ color: common.error, fontWeight: 600 }}>
                    {valitdationT('invalidEmail')}
                  </Typography>
                )}
              </InputLabel>
              <Input
                className={styles.custom_input}
                placeholder={profileT('fillEmail')}
                fullWidth
                disabled={!!userData.isEmailConfirmed}
                value={formData?.email ?? userData.email}
                id="email-input"
                onChange={(e) => handleOnChange(e, FIELD_NAME_USER_INFO.EMAIL)}
              />
            </FormControl>
            <FormControl
              className={cx(styles.form_control, { [styles.is_disabled]: !!userData.login })}
            >
              <InputLabel shrink htmlFor="nickname-input">
                {profileT('login')}
              </InputLabel>
              <Input
                disabled
                className={styles.custom_input}
                placeholder={profileT('fillLogin')}
                fullWidth
                id="LOGIN-input"
                value={formData?.login ?? userData.login}
              />
            </FormControl>
            <FormControl
              className={cx(styles.form_control, {
                [styles.is_error]: !validField[FIELD_NAME_USER_INFO.FIRST_NAME],
              })}
              sx={{ margin: 0 }}
            >
              <InputLabel shrink htmlFor="lastName-input">
                {validField[FIELD_NAME_USER_INFO.FIRST_NAME] ? (
                  profileT('firstName')
                ) : (
                  <Typography>{valitdationT('maxLength', { length: 16 })}</Typography>
                )}
              </InputLabel>
              <Input
                className={styles.custom_input}
                placeholder={profileT('fillFirstName')}
                value={formData?.name ?? userData.name}
                fullWidth
                id="firstName-input"
                onChange={(e) => handleOnChange(e, FIELD_NAME_USER_INFO.FIRST_NAME)}
              />
            </FormControl>
            <FormControl
              className={cx(styles.form_control, {
                [styles.is_error]: !validField[FIELD_NAME_USER_INFO.LAST_NAME],
              })}
            >
              <InputLabel shrink htmlFor="lastName-input">
                {validField[FIELD_NAME_USER_INFO.LAST_NAME] ? (
                  profileT('lastName')
                ) : (
                  <Typography>{valitdationT('maxLength', { length: 16 })}</Typography>
                )}
              </InputLabel>
              <Input
                className={styles.custom_input}
                placeholder={profileT('fillLastName')}
                fullWidth
                value={formData?.surname ?? userData.surname}
                id="lastName-input"
                onChange={(e) => handleOnChange(e, FIELD_NAME_USER_INFO.LAST_NAME)}
              />
            </FormControl>
            <FormControl
              className={cx(styles.form_control, {
                [styles.is_error]: !validField[FIELD_NAME_USER_INFO.PHONE],
              })}
            >
              <InputLabel shrink htmlFor="phone-input">
                {!validField[FIELD_NAME_USER_INFO.PHONE] ? (
                  <Typography sx={{ color: common.error, fontWeight: 600 }}>
                    {valitdationT('invalidPhoneNumber')}
                  </Typography>
                ) : (
                  `${profileT('phoneNumber')} *`
                )}
              </InputLabel>
              <InputMask
                id="phone-input"
                required
                mask="+7(999)-999-99-99"
                disabled={userData.isPhoneConfirmed}
                value={formData?.phone ?? userData.phone}
                onChange={(e) => handleOnChange(e, FIELD_NAME_USER_INFO.PHONE)}
                onBlur={handleOnBlurPhone}
              >
                {() => (
                  <TextField
                    sx={{ mt: 1 }}
                    size="small"
                    variant="outlined"
                    fullWidth
                    placeholder="+7(900)-000-00-00"
                    error={!validField[FIELD_NAME_USER_INFO.PHONE]}
                  />
                )}
              </InputMask>
            </FormControl>
            <FormControl
              className={cx(styles.form_control, {
                [styles.is_error]: !validField[FIELD_NAME_USER_INFO.BIRTHDAY_AT],
              })}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <InputLabel
                    shrink
                    htmlFor="birthDay-input"
                    className={cx({ [styles.is_disabled]: !!userData.birthdayAt })}
                  >
                    {validField.birthdayAt ? (
                      profileT('dateOfBirth')
                    ) : (
                      <Typography sx={{ color: common.error, fontWeight: 600 }}>
                        {valitdationT('invalidBirthDay')}
                      </Typography>
                    )}
                  </InputLabel>
                  <Box className={styles.datePicker_box}>
                    <DatePicker
                      disabled={!!userData.birthdayAt}
                      defaultValue={userData.birthdayAt ? dayjs(userData.birthdayAt) : dayjs(null)}
                      onChange={(e) => handleOnChangeDatePicker(e)}
                    />
                  </Box>
                </DemoContainer>
              </LocalizationProvider>
            </FormControl>
            <Button
              fullWidth
              disabled={
                !Object.keys(formData).length ||
                !validField[FIELD_NAME_USER_INFO.EMAIL] ||
                !validField[FIELD_NAME_USER_INFO.PHONE] ||
                !validField[FIELD_NAME_USER_INFO.BIRTHDAY_AT] ||
                !validField[FIELD_NAME_USER_INFO.LAST_NAME] ||
                !validField[FIELD_NAME_USER_INFO.FIRST_NAME] ||
                isLoading
              }
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
            >
              {profileT('save')}
            </Button>
          </Box>
        </Box>
        <Result
          style={{ mt: 2 }}
          message={apiError || commonT(error)}
          type={error ? RESULT.ERROR : type}
          isShow={!!(apiError || error)}
          hasAction
          onClose={handleOnClose}
        />
      </Box>
    </>
  );
}

export default UserInfo;
