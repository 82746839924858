import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import EmojiEventsRoundedIcon from '@mui/icons-material/EmojiEventsRounded';
import RedeemIcon from '@mui/icons-material/Redeem';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AttractionsIcon from '@mui/icons-material/Attractions';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import PeopleIcon from '@mui/icons-material/People';
import React from 'react';
import Level from 'components/level/level';
import { ISiteLevels } from 'types/site';
import styles from './userLevels.module.scss';

export interface Iproperties {
  key: string;
  id: number;
  isActive: boolean;
}

const init = {
  minDepositBorder: '',
  cashbackPercent: '',
  image: '',
  maxDepositBorder: '',
  name: '',
  id: '',
  withdrawBorder: '',
  properties: [],
};

const iconsObj = {
  support247: AccountCircleIcon,
  holidayAndBirthdayGifts: RedeemIcon,
  vipManager: EmojiEventsRoundedIcon,
  fortuneWheel: AttractionsIcon,
  multiPromoCode: DynamicFormIcon,
  telegramVk: PeopleIcon,
  monthlyBonus15th: StarBorderRoundedIcon,
} as { [x: string]: any };

function UserLevels() {
  const { t } = useTranslation('levels');
  const [currentLevel, setCurrentLevel] = useState<ISiteLevels>(init);
  const userData = useSelector<any>((state) => state.userData) as any;

  const { levelGroups } = useSelector<any>((state) => state.siteConfigs) as { [x: string]: any };

  const renderItem = (item: any, index: number, id: number | string) => {
    return (
      <Box className={cx(styles.benefit, { [styles.isActive]: item.id === id })} key={index}>
        <Box className={styles.benefit_img} style={{ backgroundImage: `url(${item.image})` }} />
        <Box className={styles.benefit_name}>{item.name}</Box>
        <Box className={styles.benefit_title}>
          <Box className={styles.item}>
            <Box className={styles.item_box}>
              <Typography component="p" className={styles.title}>
                {item?.withdrawBorder}
              </Typography>
              <Typography component="p">{t('widthDrawPerDay')}</Typography>
            </Box>
          </Box>
        </Box>
        <Box className={styles.benefit_title}>
          <Box className={styles.item}>
            <Box className={styles.item_box}>
              <Typography component="p" className={styles.title}>
                {item?.minDepositBorder ?? ''}
              </Typography>
              <Typography component="p"> {t('from')}</Typography>
            </Box>
            <Box className={styles.vertical_line} />
          </Box>
          <Box className={styles.item}>
            <Box className={styles.item_box}>
              <Typography component="p" className={styles.title}>
                {item?.maxDepositBorder ?? ''}
              </Typography>
              <Typography component="p">{t('to')}</Typography>
            </Box>
            <Box className={styles.vertical_line} />
          </Box>
          <Box className={styles.item}>
            <Box className={styles.item_box}>
              <Typography component="p" className={styles.title}>
                {`${item?.cashbackPercent ?? ''}%`}
              </Typography>
              <Typography component="p">{t('cashback')}</Typography>
            </Box>
          </Box>
        </Box>
        <Box className={styles.benefit_content}>
          {item.properties.map((el: Iproperties, index: number) => {
            return (
              <Box key={index} className={cx(styles.item, { [styles.disabled]: !el.isActive })}>
                <Box className={styles.img}>
                  <Box className={styles.img}>{React.createElement(iconsObj[el.key])}</Box>
                </Box>
                <Typography component="p">{t(`${el.key}`)}</Typography>
                <Box className={styles.icon}>{React.createElement(CheckIcon)}</Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    if (userData?.levelGroup?.id && levelGroups) {
      const current =
        levelGroups.filter((el: ISiteLevels) => el.id === userData.levelGroup?.id)?.[0] ?? {};
      setCurrentLevel(current);
    }
  }, [userData?.levelGroup?.id]);

  return (
    <Box className={styles.main_area}>
      <Box className={styles.main_title}>
        {t('userLevels')}
        <Box>{React.createElement(RocketLaunchIcon)}</Box>
      </Box>
      <Box className={styles.levels_block}>
        <Level variant="determinate" className={styles.bg_levels} />
        <Box className={styles.benefit}>
          <Box sx={{ m: 1, textAlign: 'center' }}>{t('currentLevelTitle')}</Box>
          <Box className={styles.benefit_title}>
            <Box className={styles.item}>
              <Box className={styles.item_box}>
                <Typography component="p" className={styles.title}>
                  {currentLevel?.withdrawBorder}
                </Typography>
                <Typography component="p">{t('widthDrawPerDay')}</Typography>
              </Box>
            </Box>
          </Box>
          <Box className={styles.benefit_title}>
            <Box className={styles.item}>
              <Box className={styles.item_box}>
                <Typography component="p" className={styles.title}>
                  {currentLevel?.minDepositBorder ?? ''}
                </Typography>
                <Typography component="p"> {t('from')}</Typography>
              </Box>
              <Box className={styles.vertical_line} />
            </Box>
            <Box className={styles.item}>
              <Box className={styles.item_box}>
                <Typography component="p" className={styles.title}>
                  {currentLevel?.maxDepositBorder ?? ''}
                </Typography>
                <Typography component="p">{t('to')}</Typography>
              </Box>
              <Box className={styles.vertical_line} />
            </Box>
            <Box className={styles.item}>
              <Box className={styles.item_box}>
                <Typography component="p" className={styles.title}>
                  {`${currentLevel?.cashbackPercent ?? ''}%`}
                </Typography>
                <Typography component="p">{t('cashback')}</Typography>
              </Box>
            </Box>
          </Box>
          <Box className={styles.benefit_content}>
            {currentLevel.properties.map((el: Iproperties, index: number) => {
              return (
                <Box key={index} className={cx(styles.item, { [styles.disabled]: !el.isActive })}>
                  <Box className={styles.img}>
                    <Box className={styles.img}>{React.createElement(iconsObj[el.key])}</Box>
                  </Box>
                  <Typography component="p">{t(`${el.key}`)}</Typography>
                  <Box className={styles.icon}>{React.createElement(CheckIcon)}</Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
      <Box className={styles.all_levels}>
        <Box className={styles.title}>
          {t('allLevels')}
          <Typography component="p">{t('allLevelsSub')}</Typography>
        </Box>
        <Box className={styles.content}>
          {levelGroups.map((el: any, index: number) => {
            return renderItem(el, index, currentLevel.id);
          })}
        </Box>
      </Box>
    </Box>
  );
}

export default UserLevels;
