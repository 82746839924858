import { Box, Typography } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import LoaderWithBg from 'components/loader/loader';
import WinnersCard from 'components/winnersCard/winnersCard';
import { useStatistics } from 'hooks/statistics/useStatistics';
import styles from './statisticsItem.module.scss';

interface StatisticsItemProps {
  value: string;
  translaste: string;
  isMobile?: boolean;
}

const titleList = [
  ['nameGame', 'played', 'game'],
  ['userName', 'amount', 'game'],
  ['userName', 'amount', 'game'],
  ['nameGame', 'disLikedCount', 'game'],
];

const keys = [
  ['name', 'playedCount', ''],
  ['login', 'winAmount', 'currency'],
  ['login', 'winAmount', 'currency'],
  ['gameName', 'unlikedCount', ''],
];

function StatisticsItem({ value, translaste, isMobile }: StatisticsItemProps) {
  const { t } = useTranslation(translaste);
  const [itemList, setItemList] = useState<any[]>([]);
  const [itemTitle, setItemTitle] = useState<any[]>([]);

  const { isLoading, getPopularGames, getTopWinsGames, getTopWinsLast24Games, getUnlikedGames } =
    useStatistics();

  const getData = async (value: string) => {
    let result;
    switch (value) {
      case '0':
        result = await getPopularGames();
        break;
      case '1':
        result = await getTopWinsGames();
        break;
      case '2':
        result = await getTopWinsLast24Games();
        break;
      case '3':
        result = await getUnlikedGames();
        break;
      default:
        null;
    }
    if (result?.status === 200) {
      setItemList(result.data);
      const titles = titleList[+value] ?? titleList[0];
      setItemTitle(titles);
    } else {
      setItemList([]);
    }
  };

  useEffect(() => {
    getData(value);
  }, [value]);

  return (
    <Box className={styles.content}>
      <Box className={cx(styles.item_list_title, { [styles.isMobile]: isMobile })}>
        {itemTitle.map((el, index) => {
          return (
            <Typography
              sx={{ fontSize: '12px', width: '120px', textAlign: 'center' }}
              key={index}
              component={'span'}
            >
              {t(el)}
            </Typography>
          );
        })}
      </Box>
      {isLoading ? (
        <LoaderWithBg isOpen={isLoading} />
      ) : (
        <>
          {itemList.length ? (
            itemList.map((item, index) => {
              return (
                <Fragment key={index}>
                  <Box className={styles.item_list}>
                    <Box className={styles.item} sx={{ flexBasis: '100%' }}>
                      <Typography className={styles.name} component={'span'}>
                        {item[keys[+value][0]]}
                      </Typography>
                      <Typography className={styles.amount} component={'span'}>
                        {item[keys[+value][1]]} {item[keys[+value][2]]}{' '}
                        <Typography
                          sx={{ textTransform: 'lowercase', fontSize: 13 }}
                          component={'span'}
                        >
                          {' '}
                          {value === '0' ? t('raz') : ''}
                        </Typography>
                      </Typography>
                      <WinnersCard data={item} />
                    </Box>
                  </Box>
                  <Box className={styles.divider} />
                </Fragment>
              );
            })
          ) : (
            <Typography sx={{ m: 3 }}> {t('noResultStatistics')}</Typography>
          )}
        </>
      )}
    </Box>
  );
}

export default StatisticsItem;
