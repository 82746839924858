import styles from './card.module.scss';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import _color from 'styles/colors.module.scss';
import card from 'assets/payment/cardLogo.png';

interface CardProps {
  cardNumber: string | null;
}

function Card({ cardNumber }: CardProps) {
  return (
    <>
      {cardNumber ? (
        <>
          <main>
            <aside className={styles.card_front}>
              <label className={styles.number} htmlFor="cardNumber">
                {cardNumber
                  .replace(/[^0-9]/g, '')
                  .replace(/\W/gi, '')
                  .replace(/(.{4})/g, '$1 ')}
                <ContentCopyIcon
                  className={styles.copy_icon}
                  onClick={() => {
                    navigator.clipboard.writeText(`${cardNumber}`);
                  }}
                />
              </label>
              <label className={styles.name} htmlFor="cardHolder">
                {' '}
                Magnit - X
              </label>
              <label className={styles.expiry} htmlFor="expiryMonth">
                XX/XX
              </label>
              <img
                className={styles.cardLogo}
                data-v-5d206127=""
                data-v-8fcb32d4=""
                style={{ opacity: 1 }}
                src={card}
              />

              <div className={styles.chip}>
                <svg role="img" viewBox="0 0 100 100" aria-label="Chip">
                  <use href="#chip-lines" />
                </svg>
              </div>
              <svg
                className={styles.contactless}
                role="img"
                viewBox="0 0 24 24"
                aria-label="Contactless"
              >
                <use href="#contactless" />
              </svg>
            </aside>
          </main>

          <svg className={styles.chip_svg} id="chip">
            <g id="chip-lines">
              <polyline points="0,50 35,50"></polyline>
              <polyline points="0,20 20,20 35,35"></polyline>
              <polyline points="50,0 50,35"></polyline>
              <polyline points="65,35 80,20 100,20"></polyline>
              <polyline points="100,50 65,50"></polyline>
              <polyline points="35,35 65,35 65,65 35,65 35,35"></polyline>
              <polyline points="0,80 20,80 35,65"></polyline>
              <polyline points="50,100 50,65"></polyline>
              <polyline points="65,65 80,80 100,80"></polyline>
            </g>
          </svg>
        </>
      ) : null}
    </>
  );
}

export default Card;
