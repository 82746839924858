import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@mui/material';
import cx from 'classnames';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface IDialogsProps {
  className?: string;
  title?: string;
  contentStyle?: any;
  content?: string | ReactNode;
  ignoreBtnText?: string;
  btnText?: string;
  isOpen: boolean;
  hasLink?: string;
  imageContent?: string;
  onClose: () => void;
  onConfirm?: (id: string, comment?: string) => void;
  onChange?: (isConfirmed?: boolean, type?: string) => void;
  errorMessage?: string;
}

const Dialogs = ({
  title,
  content,
  isOpen,
  onClose,
  btnText,
  hasLink,
  imageContent,
  ignoreBtnText,
  contentStyle,
  className,
}: IDialogsProps) => {
  const { t } = useTranslation('common');

  const handleOnCLick = (hasLink?: string) => {
    if (hasLink) {
      window.open(hasLink, '_self');
    } else {
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} onClick={onClose}>
      <Box className={cx(className)}>
        {title ? <DialogTitle>{title}</DialogTitle> : null}
        <img src={imageContent} style={{ width: '50', objectFit: 'contain' }} alt="image" />
        {content ? (
          <DialogContent sx={{ ...contentStyle }}>
            <DialogContent>{content}</DialogContent>
          </DialogContent>
        ) : null}
        <DialogActions sx={{ justifyContent: ' center' }}>
          {ignoreBtnText !== 'hide' ? (
            <Button variant="contained" onClick={onClose}>
              {ignoreBtnText ?? t('cancel')}
            </Button>
          ) : null}
          <Button
            variant="contained"
            onClick={() => handleOnCLick(hasLink)}
            autoFocus
            sx={{ m: 2, backgroundColor: '#64b5ef' }}
          >
            {btnText ? t(btnText as string) ?? t('delete') : null}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default Dialogs;
