import { Box, Button, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as FullScreenIcon } from 'assets/icons/fullscreen.svg';
import { useEffect, useRef, useState } from 'react';
import noImage from 'assets/games/noImage.webp';
import { FIELD_NAME } from 'types/login';
import { useGame } from 'hooks/playGames/useGame';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'context/auth/authContext';
import { useModalContext } from 'context/modal/modalContext';
import common from 'styles/colors.module.scss';
import { MODAL_TYPES } from 'types/modals';
import LoaderWithLogo from 'components/loader/loaderWithLogo';
import { IconClose } from 'components/closeButton/closeButton';
import styles from './gameDemo.module.scss';
import { routing } from 'constants/routing';

export const GameDemo = () => {
  const { t } = useTranslation('common');
  const { playGameDemo } = useGame();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const containerRef = useRef<HTMLIFrameElement>(null);
  const { openModalWithData } = useModalContext();
  const { state } = useLocation();
  const [iframeUrl, setIframeUrl] = useState('');
  const [image, setImage] = useState('');
  const [gameName, setGameName] = useState('');
  const [favoriteCount, setGameIsFavoriteCount] = useState('');
  const [unlikedCount, setGameUnlikedCount] = useState('');
  const [, setIsFullScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  let timeOutForLoading: ReturnType<typeof setTimeout>;
  let timeOutId: ReturnType<typeof setTimeout>;

  const handlePlayGame = async (providerId: string, gameId: string) => {
    if (user) {
      openModalWithData(MODAL_TYPES.CHOOSE_BALANCE, {
        gameId: gameId,
        providerId: providerId,
        image,
        name: gameName,
      });
    } else {
      openModalWithData(FIELD_NAME.LOGIN_FOR_PLAY_GAME, {
        gameId: gameId,
        providerId: providerId,
        image: iframeUrl,
        name: gameName,
      });
    }
  };

  const toggleFullscreen = () => {
    const container: any = containerRef.current;

    if (!document.fullscreenElement) {
      if (container.requestFullscreen) {
        container.requestFullscreen();
      } else if (container.webkitRequestFullscreen) {
        container.webkitRequestFullscreen();
      } else if (container.mozRequestFullScreen) {
        container.mozRequestFullScreen();
      } else if (container.msRequestFullscreen) {
        container.msRequestFullscreen();
      }
    } else {
      document.exitFullscreen();
    }

    timeOutId = setTimeout(() => {
      setIsFullScreen(document.fullscreenElement === containerRef.current);
    }, 100);
  };

  const handleClose = async () => {
    navigate(routing.home);
  };

  useEffect(() => {
    const getDameDemoData = async (providerId: string, gameId: string) => {
      const res = await playGameDemo(providerId, gameId);
      if (res?.status === 201) {
        const { data } = res;
        setIframeUrl(data.demoURL);
        setImage(state.image);
        setGameIsFavoriteCount(data.gameCounts.favoriteCount);
        setGameUnlikedCount(data.gameCounts.unlikedCount);
        timeOutForLoading = setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }
    };

    if (state?.launchURL && state?.sessionId) {
      setIframeUrl(state.launchURL);
      setImage(state.image);
    } else {
      if (state?.gameId !== undefined && state?.providerId !== undefined) {
        getDameDemoData(state.providerId, state.gameId);
        setImage(state.image);
        setGameName(state.name);
      }
    }
    return () => {
      clearTimeout(timeOutForLoading);
      clearTimeout(timeOutId);
    };
  }, [state]);

  return (
    <>
      {isLoading ? <LoaderWithLogo isOpen={isLoading} /> : null}
      <Box sx={{ width: '100%', display: 'flex' }}>
        <Box className={styles.play_games_container}>
          <Box className={styles.iframe}>
            <Box className={styles.content} ref={containerRef}>
              <Box className={styles.content_header}>
                <Typography align="left">{t('demoVersion')}</Typography>
                <IconClose className={styles.iframe_close_btn} onClose={handleClose} />
              </Box>
              <iframe src={iframeUrl} width={'100%'} height={'100%'} allowFullScreen seamless />
            </Box>
            <Box className={styles.fullscreen}>
              <FullScreenIcon onClick={toggleFullscreen} />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography component={'span'} color={common.mainOrange}>
                  {`Favorite:${favoriteCount}`}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  component={'span'}
                  color={common.mainOrange}
                >{`Unliked:${unlikedCount}`}</Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: '80%',
              display: 'flex',
            }}
          >
            <Box className={styles.card}>
              <Box className={styles.img}>
                <img
                  src={image}
                  alt="game"
                  onError={({ currentTarget }: any) => (currentTarget.src = noImage)}
                  width={169}
                />
              </Box>
            </Box>
            <Box className={styles.game_info}>
              <Typography align="left">{gameName}</Typography>
              <Box className={styles.action}>
                <Button
                  className={styles.demo}
                  onClick={() => handlePlayGame(state.providerId, state.gameId)}
                >
                  {t('play')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
