import { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import cx from 'classnames';
import { useUserData } from 'hooks/user/useUserData';
import { useTranslation } from 'react-i18next';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LoaderWithBg from 'components/loader/loader';
import { common, dialog } from 'constants/common';
import { useBonus } from 'hooks/user/useBonus';
import Dialogs from 'components/dialog/dialogs';
import Result from 'components/alert/alert';
import { RESULT } from 'types/common';
import { IBonus } from 'types/bonus';
import { useSelector } from 'react-redux';
import _color from 'styles/colors.module.scss';
import styles from './bonuses.module.scss';

function Bonuses() {
  const { getUserBonuses, bonusData, isLoading } = useUserData();
  const { applyBonus, cancelBonus, isLoading: bonusLoading, error, resetError } = useBonus();
  const { t } = useTranslation('deposit');
  const { t: commonT } = useTranslation('common');
  const [activeBonus, setActiveBonus] = useState<IBonus | null>(null);
  const [assumeActiveBonusId, setAssumeActiveBonusId] = useState<string>('');
  const [bonusList, setBonusList] = useState<IBonus[]>([]);
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [isOpenCancel, setOpenCancel] = useState(false);

  let timeOutId: ReturnType<typeof setTimeout>;
  const userData = useSelector<any>((state) => state.userData) as any;

  const bonusAmount = userData?.balance.filter((el: any) => el.id === 1)?.[0]?.amount;

  const cancelOrApplyBonus = async (bonusId: number, type: string) => {
    const activeBonusItem =
      type === common.APPLY ? await applyBonus(bonusId) : await cancelBonus(bonusId);
    const filteredItem = bonusData?.filter((el: any) => el.id === activeBonusItem?.data?.id);

    if (filteredItem?.length) {
      filteredItem[0].isActive = activeBonusItem?.data?.isActive;
      filteredItem[0].isActivated = activeBonusItem?.data?.isActivated;
    }
    setBonusList([...bonusData]);
    setActiveBonus(type === common.APPLY ? activeBonusItem?.data : null);
  };

  const handleOnConfirm = async (id: string) => {
    const canceledItem = await cancelBonus(activeBonus?.id);
    if (canceledItem?.data) {
      const filteredItem = bonusList?.filter((el: IBonus) => el.id === activeBonus?.id);
      if (filteredItem?.length) {
        filteredItem[0].isActive = canceledItem?.data?.isActive;
        filteredItem[0].isActivated = canceledItem?.data?.isActivated;
      }
      const appliedItem = await applyBonus(id);
      if (appliedItem?.data) {
        const filteredItem = bonusList?.filter((el: IBonus) => el.id === +id);
        if (filteredItem?.length) {
          filteredItem[0].isActive = appliedItem?.data?.isActive;
          setBonusList([...bonusList]);
          setActiveBonus({ ...filteredItem[0] });
        }
        setStatusMessage(commonT(RESULT.SUCCESS));
      }
    } else {
      setStatusMessage(commonT('somethingWentWrong'));
    }
    timeOutId = setTimeout(() => {
      setStatusMessage('');
      if (error) resetError();
    }, 3000);
  };

  const handleOnClick = (type: string, bonusId: number) => {
    if (type === common.APPLY) {
      if (!activeBonus) {
        cancelOrApplyBonus(bonusId, type);
      } else {
        setOpen(true);
        setAssumeActiveBonusId(bonusId + '');
      }
    } else {
      setOpenCancel(true);
      setAssumeActiveBonusId(bonusId + '');
    }
  };

  const handleOnConfirmCancel = (id: string) => {
    cancelOrApplyBonus(+id, common.CANCEL);
  };

  useEffect(() => {
    getUserBonuses();
    return () => {
      clearTimeout(timeOutId);
    };
  }, []);

  useEffect(() => {
    setBonusList(bonusData);
    const activeBonusItem = bonusData?.filter((el: any) => el.isActive)?.[0] ?? null;
    setActiveBonus(activeBonusItem);
  }, [bonusData]);

  return (
    <Box className={styles.main_area}>
      <Box className={styles.main_title}>{t('bonus')}</Box>

      {!activeBonus && bonusList.length ? (
        bonusList.filter((el) => !el.isActivated)?.length ? (
          <Box className={styles.main_bonus_info}>
            <Typography variant="subtitle1" component="p">
              {t('userBonusInfo')}
            </Typography>
          </Box>
        ) : (
          <Box className={styles.main_bonus_info}>
            <Typography variant="subtitle1" component="p">
              {t('userBonusInfoNoBonus')}
            </Typography>
          </Box>
        )
      ) : null}

      <Box className={styles.bonuses_area}>
        <Result
          style={{ mt: 2 }}
          message={statusMessage || error}
          type={error ? RESULT.ERROR : RESULT.SUCCESS}
          isShow={!!(statusMessage || error)}
        />

        {bonusList.length ? (
          bonusList.map((elm: any, i: number) => {
            return (
              <Box
                key={i}
                className={cx(styles.bonus_info, {
                  [styles.isActive]: elm.isActive,
                  [styles.isActivated]: elm.isActivated,
                })}
              >
                <Box className={styles.bonus_title_area}>
                  <Box sx={{ flexDirection: 'column', minWidth: '150px' }}>
                    <Box>
                      {elm.isActive && (
                        <CheckCircleIcon sx={{ color: _color.darkGreen, fontSize: '18px' }} />
                      )}
                      <Typography
                        fontWeight={900}
                        component={'span'}
                        fontSize={12}
                        color={elm.isActive ? _color.darkGreen : _color.error}
                      >
                        {elm.isActive
                          ? commonT('bonusIsActive')
                          : elm.isActivated
                            ? ''
                            : commonT('passiveBonus')}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{ fontSize: '1.2rem', maxWidth: '250px', width: 'auto' }}
                      variant={'h4'}
                    >
                      {elm.name.includes('WHEEL_BONUS[') ? commonT('wheel') : elm.name}
                    </Typography>
                  </Box>
                  <Typography component={'span'} fontSize={'11px'}>
                    {elm.comment}
                  </Typography>
                </Box>
                <Box className={styles.info_amount}>
                  <Box className={styles.item}>
                    <Typography component={'span'} sx={{ fontWeight: 'bolder' }}>
                      {commonT('amount')}:{' '}
                    </Typography>
                    <Typography component={'span'}>
                      {elm.amount} {userData?.currency?.code}
                    </Typography>
                  </Box>
                  <Box className={cx(styles.item, styles.weiger_info)}>
                    <Box className={styles.weiger}>
                      <Typography component={'span'} sx={{ color: _color.error }}>
                        {commonT('weiger')}:{' '}
                      </Typography>
                      <Typography component={'span'} sx={{ color: _color.error }}>
                        {' '}
                        × {elm.weiger}
                      </Typography>
                    </Box>
                    <Box className={styles.weiger}>
                      <Typography component={'span'} sx={{ color: _color.darkGreen }}>
                        {commonT('withdrawFrom')}:
                      </Typography>
                      <Typography component={'span'} sx={{ color: _color.darkGreen }}>
                        {elm.weiger * elm.amount} {userData?.currency?.code}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box className={styles.button_area}>
                  <Button
                    sx={{
                      backgroundColor:
                        elm.isActive || elm.isActivated ? _color.lightGrey : _color.darkGreen,
                    }}
                    disabled={elm.isActive || elm.isActivated}
                    onClick={() => handleOnClick(common.APPLY, elm.id)}
                  >
                    {commonT('apply')}
                  </Button>
                  {(elm.isActive && activeBonus) || elm.isActivated ? (
                    <Button
                      disabled={!elm.isActive || elm.isActivated}
                      sx={{
                        backgroundColor:
                          (!elm.isActive && activeBonus) || elm.isActivated
                            ? _color.lightGrey
                            : _color.error,
                      }}
                      onClick={() => handleOnClick(common.CANCEL, elm.id)}
                    >
                      {commonT('refuse')}
                    </Button>
                  ) : null}
                </Box>
              </Box>
            );
          })
        ) : (
          <Box className={styles.haveNoBonus_area}>
            <Box className={styles.haveNoBonus_box}>
              <DescriptionIcon />
              <Typography fontSize={'12px'}>{t('haveNoBonuses')}</Typography>
            </Box>
          </Box>
        )}

        <Box sx={{ color: 'black' }}>
          <Dialogs
            id={assumeActiveBonusId}
            title={commonT(dialog.CANCEL_BONUS)}
            content={commonT(dialog.CANCEL_BONUS_MESSAGE, {
              amount: `${bonusAmount} ${userData?.currency?.code}`,
            })}
            subContent={commonT('subcontent')}
            isOpen={open}
            onClose={() => setOpen(false)}
            onConfirm={handleOnConfirm}
            btnText={commonT('yes')}
            ignoreBtnText={commonT('no')}
            contentStyle={{ padding: '0 12px' }}
          />
          {isOpenCancel ? (
            <Dialogs
              id={assumeActiveBonusId}
              title={''}
              content={commonT(dialog.CANCEL_ACTIVE_BONUS)}
              subContent={commonT('subcontent')}
              isOpen={isOpenCancel}
              onClose={() => setOpenCancel(false)}
              onConfirm={handleOnConfirmCancel}
              btnText={commonT('yes')}
              ignoreBtnText={commonT('no')}
              contentStyle={{ padding: '0 12px' }}
            />
          ) : null}
        </Box>
      </Box>
      {bonusLoading || isLoading ? <LoaderWithBg isOpen={bonusLoading || isLoading} /> : null}
    </Box>
  );
}

export default Bonuses;
