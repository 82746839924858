import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import moment from 'moment';
import { useModalContext } from 'context/modal/modalContext';
import { MODAL_ACTIVE_TABS, MODAL_TYPES } from 'types/modals';
import { INotificationItem } from 'types/notification';
import { useSelector } from 'react-redux';
import styles from './notificationItem.module.scss';

interface NotificationItemProps {
  /**TODO: write real types after getting all final data from BE */
  item: INotificationItem;
  type?: string;
  index: number;
  wrapperClassName?: string;
}

const notificationRefs = {
  BONUS: {
    modalType: MODAL_TYPES.DEPOSIT,
    activeTab: MODAL_ACTIVE_TABS.BONUSES,
    buttonKey: 'goToBonus',
  },
  USER_VERIFICATION: {
    modalType: MODAL_TYPES.PROFILE,
    activeTab: MODAL_ACTIVE_TABS.VERIFICATION,
    buttonKey: 'goToVerification',
  },
};

export type NotificationRefKeys = keyof typeof notificationRefs;

function NotificationItem({ item, wrapperClassName, index, type }: NotificationItemProps) {
  const { t } = useTranslation('common');
  const { t: notificationT } = useTranslation('notification');
  const { openModalWithData } = useModalContext();
  const userData = useSelector<any>((state) => state.userData) as any;

  const handleOnClick = (ref: NotificationRefKeys) => {
    openModalWithData(notificationRefs[ref].modalType, {
      activeTab: notificationRefs[ref].activeTab,
    });
  };

  return (
    <Box className={cx(styles.notifications_item, wrapperClassName)} key={index}>
      <Box className={styles.content}>
        {/**TODO: uncomment when need to show title*/}
        {/* {type === 'message' ? (
          <Box className={styles.title}>
            <Typography>{item.title}</Typography>
          </Box>
        ) : null} */}
        <Box>
          <Typography>{moment(item.createdAt).format('DD/MM/Y HH:mm:ss')}</Typography>
        </Box>
        <Box
          className={cx({
            [styles.new]: !item.isViewed,
            [styles.read]: item.isViewed,
          })}
        >
          {item.isViewed ? t('read') : t('new')}
        </Box>
      </Box>
      <Box className={styles.text}>
        {type === 'message' ? (
          <Typography>{item.message}</Typography>
        ) : (
          <>
            <Typography>
              {item?.props?.amount !== undefined
                ? notificationT(item.key, {
                    amount: `${item?.props?.amount}  ${userData?.currency?.code}`,
                  })
                : notificationT('bonusRecieve')}
            </Typography>
            {item.ref ? (
              <Button variant="contained" onClick={() => handleOnClick(item.ref)}>
                {notificationT(notificationRefs[item.ref]?.buttonKey)}
              </Button>
            ) : null}
          </>
        )}
      </Box>
    </Box>
  );
}

export default NotificationItem;
