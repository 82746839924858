import { actionTypes } from 'redux/constants/actionTypes';

export const setUserData = (type) => {
  return {
    type: actionTypes.SET_USER_DATA,
    payload: type,
  };
};

export const updateUserDataForFields = (type) => {
  return {
    type: actionTypes.UPDATE_USER_DATA_FOR_FIELDS,
    payload: type,
  };
};

export const updateUserBalance = (type) => {
  return {
    type: actionTypes.UPDATE_USER_BALANCE,
    payload: type
  }
}

export const updateUserLevel = (type) => {
  return {
    type: actionTypes.UPDATE_USER_LEVEL,
    payload: type
  }
}

export const updateUserNotification = (type) => {
  return {
    type: actionTypes.UPDATE_USER_NOTIFICATIONS,
    payload: type
  }
}

export const getNotification = (data) => {
  return {
    type: actionTypes.USER_GET_NOTIFICATION,
    payload: data
  }
}

export const getMessages = (data) => {
  return {
    type: actionTypes.USER_GET_MESSAGES,
    payload: data
  }
}

export const userNotificaionsSeen = (data) => {
  return {
    type: actionTypes.USER_NOTIFICATIONS_SEEN,
    payload: data
  }
}

export const userMessagesSeen = (data) => {
  return {
    type: actionTypes.USER_MESSAGES_SEEN,
    payload: data
  }
}

export const getAllNotifications = (data) => {
  return {
    type: actionTypes.GET_ALL_NOTIFICATIONS,
    payload: data
  }
}