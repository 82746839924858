export const actionTypes = {
  SET_CURRENCY_DATA: 'SET_CURRENCY_DATA',
  UPDATE_USER_BALANCE: 'UPDATE_USER_BALANCE',
  UPDATE_USER_LEVEL: 'UPDATE_USER_LEVEL',
  SET_LANGUAGES_DATA: 'SET_LANGUAGES_DATA',
  SET_DEVICE_TYPE: 'SET_DEVICE_TYPE',
  SET_CATEGORIES: 'SET_CATEGORIES',
  SET_TOP_WINNERS: 'SET_TOP_WINNERS',
  SET_CONFIGS: 'SET_CONFIGS',
  SET_USER_DATA: 'SET_USER_DATA',
  SET_PROVIDERS: 'SET_PROVIDERS',
  SET_PROVIDERS_WITHOUT_GAMES: 'SET_PROVIDERS_WITHOUT_GAMES',
  UPDATE_USER_DATA_FOR_FIELDS: "UPDATE_USER_DATA_FOR_FIELDS",
  UPDATE_USER_NOTIFICATIONS: "UPDATE_USER_NOTIFICATIONS",
  USER_NOTIFICATIONS_SEEN: "USER_NOTIFICATIONS_SEEN",
  USER_MESSAGES_SEEN: "USER_MESSAGES_SEEN",
  USER_GET_NOTIFICATION: "USER_GET_NOTIFICATION",
  USER_GET_MESSAGES: "USER_GET_MESSAGES",
  GET_ALL_NOTIFICATIONS: "GET_ALL_NOTIFICATIONS",
};
