import { useState, useEffect } from 'react';
import { Snackbar, Alert } from '@mui/material';
import _color from 'styles/colors.module.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { routing } from 'constants/routing';

function AutoSnackbar() {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('common');
  const userData = useSelector<any>((state) => state.userData) as any;

  let timer: ReturnType<typeof setInterval>;

  useEffect(() => {
    if (
      userData &&
      userData.isWheelEnabled &&
      userData.spinDiff === null &&
      location.pathname !== routing.wheel
    ) {
      setOpen(true);
      timer = setInterval(() => {
        if (!open) setOpen(true);
      }, 60000);
    }
    return () => clearInterval(timer);
  }, [userData?.isWheelEnabled, userData?.spinDiff]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      sx={{
        '&.MuiSnackbar-root': { top: '78px' },
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity="success"
        variant="filled"
        sx={{ backgroundColor: _color.mainOrange, color: _color.white }}
      >
        {t('wheelAvailable')}
      </Alert>
    </Snackbar>
  );
}

export default AutoSnackbar;
