import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BonusCarousel from 'components/bonusCard/carouselBonus';
import BonusCard from 'components/bonusCard/bonusCard';
import { mocked_Bonuses } from './mockedData';
import { useMemo } from 'react';
import { useStorage } from 'hooks/authHooks/useStorage';
import styles from './bonuses.module.scss';

export const BonusesPage = () => {
  const { t } = useTranslation('bonus');
  const { readStorageData } = useStorage();
  const lang = readStorageData('siteLanguage') ?? 'ru';
  const cards = useMemo(() => {
    const key = lang as string;
    return mocked_Bonuses[key].map((el: any, index: number) => {
      return {
        key: index,
        content: (
          <BonusCard
            image={el.icon}
            content={el.description.content}
            title={el.title}
            buttonContent={el.links}
          />
        ),
      };
    });
  }, [lang]);

  return (
    <Box className={styles.main}>
      <Box className={styles.title}>
        <Typography align="center">{t('ourBonuses')}</Typography>
      </Box>
      <BonusCarousel cards={[...cards]} height="456px" width="90%" offset={2} showArrow={false} />
    </Box>
  );
};
