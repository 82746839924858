import http2 from 'http2-common';
import http from 'http-common';

class UserDataService {
  getUserData = (headers) => {
    return http2.get(`/users/one`, { headers });
  };

  getUserBalance = (headers) => {
    return http2.get(`/users/balances/all`,{ headers });
  };

   getUserBalanceChanges = (headers, query='') => {
    return http2.get(`/users/balance-transfers?${query}`,{ headers });
  };
 
  getUsersFavoriteGames = (headers, query) => {
    return http2.get(`/games/favorite?${query}`, { headers });
  };

  getUsersRecentGames = (headers, forModal) => {
    return http2.get(`/${forModal ? 'users' : 'games'}/last-played`, { headers });
  };
  
  getUsersLevelGroup = (headers) => {
    return http2.get(`/users/level-group`, { headers });
  };

  userNotificationsSeen = (headers, body) => {
    return http2.put('/users/notifications', body, { headers })
  }
 userMessagessSeen = (headers, body) => {
    return http2.put('/users/messages', body, { headers })
  }

  getUserNotifications = (headers, query = '') => {
    return http2.get(`/users/notifications${query}`, { headers })
  }

   getUserMessages = (headers, query = '') => {
    return http2.get(`/users/messages${query}`, { headers })
  }

  sendVerificationImages = (headers, body) => {
    return http2.post('/users/verification', body, { headers });
  };

  updateUserData = (headers, userId, body) => {
    return http.put(`/users/${userId}`, body, { headers });
  };

  activateUserPromo = (headers, code, body) => {
    return http2.put(`/users/promo/${code}/activate`, body, { headers });
  };

  trasferUserBalance = (headers, body) => {
    return http2.post('/finances/transfer', body, { headers });
  };

  getUserWheelBonus = (headers) => {
    return http2.post('/games/wheel', {}, { headers });
  };

  getUserWheelDiff = (headers) => {
    return http2.get('/games/wheel', { headers });
  };

}

const UserService = new UserDataService();

export default UserService;
