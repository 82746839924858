export const isEmpty = (obj) => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const isBoolean = (value) => {
  return typeof value === 'boolean';
};

export const generateHeaders = (token, headers = {}) => {
  return { ...headers, Authorization: `Bearer ${token}` };
};

export const joinMessage = (messageArr = []) => {
  let res = '';
  for (let el of messageArr) {
    if (el.message !== undefined) {
      res += ', ' + el.message;
    } else {
      res += ', ' + el;
    }
  }
  return res.replace(/^. /, '');
};

export const makeQueryUrl = (filterData) => {
  let queryString = '';
  for (const el in filterData) {
    if (filterData[el] !== '') {
      queryString += `&${el}=${filterData[el]}`;
    }
  }
  return queryString.replace(/^. /, '');
};


export const hasEmptyProps = (objData, exeption = '') => {

  if (exeption && objData[exeption] !== undefined) {
    const { [exeption]: _, ...obj } = objData;
    return !!Object.values(obj).filter((el) => el === '').length;
  }

  return !!Object.values(objData).filter((el) => el === '').length;
};

export const getRandomNum = (min, max) => Math.ceil(Math.random() * (max - min) + min);
