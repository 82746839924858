import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NotificationItem from './notificationItem';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import { useNotification } from 'hooks/user/useNotifications';
import { useStorage } from 'hooks/authHooks/useStorage';
import { useDispatch } from 'react-redux';
import { userNotificaionsSeen } from 'redux/actions/userDataAction';
import { useModalContext } from 'context/modal/modalContext';
import { INotificationItem } from 'types/notification';
import styles from './notification.module.scss';

interface NotificationProps {
  onShowMoreExternal?: () => void;
  notificationList: INotificationItem[];
  /**TODO: write real types when backend will be ready**/

  classNames?: string;
  wrapperClassName?: string;
  forAllNotifications?: boolean;
}

const body: any = {
  /**TODO: write real types when backend will be ready**/
  notifications: [] as unknown,
};

function Notification({
  notificationList,
  classNames,
  wrapperClassName,
  forAllNotifications,
  onShowMoreExternal,
}: NotificationProps) {
  const { readUserData, updateData } = useStorage();
  const { isModalOpened } = useModalContext();

  const { getUserNotification, totalPages, forUserNotificationSeen } = useNotification();
  const { t } = useTranslation('common');
  const [allNotification, setAllNotification] = useState([]);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleOnClickMore = (event: React.ChangeEvent<unknown>) => {
    if (onShowMoreExternal) {
      onShowMoreExternal();
    } else {
      setPage((value) => (value + 1 <= totalPages ? value + 1 : value));
    }
  };

  useEffect(() => {
    const getData = async () => {
      const query = `?page=${page}`;
      const result = await getUserNotification(query);

      if (result)
        result.data.totalPages && result.data.totalPages > 1
          ? setAllNotification((allNotification) =>
              allNotification
                ? [...allNotification, ...result.data.userNotifications]
                : result.data.userNotifications,
            )
          : setAllNotification(result.data.userNotifications);
    };

    if (forAllNotifications) getData();
  }, [page, forAllNotifications]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any

    if (allNotification.length) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      allNotification.map((elm: any) => {
        if (!elm.isViewed && !body.notifications.includes(elm.id)) body.notifications.push(elm.id);
      });
    }

    const getData = async () => {
      const result = await forUserNotificationSeen(body);

      if (result) {
        const user = readUserData();
        const notificationCount = user?.user?.notifications?.count;
        const lastNotifications = user?.user?.notifications?.lastNotifications;
        if (notificationCount > 0) {
          dispatch(userNotificaionsSeen(body.notifications));
          updateData({
            notifications: {
              count: `${Number(notificationCount) - body.notifications.length}`,
              lastNotifications: lastNotifications?.filter(
                (elm: any) => !body.notifications.includes(elm.id),
              ),
            },
          });
        }

        body.notifications = [];
      }
    };

    if (body.notifications.length && !isModalOpened) getData();
  }, [allNotification, isModalOpened]);
  return (
    <Box className={cx(styles.main, wrapperClassName)}>
      <Box className={cx(styles.notifications_list, classNames)}>
        {!forAllNotifications && notificationList ? (
          notificationList.map((item, index) => {
            return index < 5 ? <NotificationItem item={item} index={index} key={index} /> : null;
          })
        ) : allNotification.length ? (
          allNotification.map((item, index) => {
            return <NotificationItem item={item} index={index} key={index} />;
          })
        ) : (
          <Typography>{t('noNotification')}</Typography>
        )}
      </Box>
      {((totalPages > 0 && page < totalPages) || !forAllNotifications) && (
        <Button sx={{ mt: 3 }} fullWidth variant="contained" onClick={handleOnClickMore}>
          {t('showMore')}
        </Button>
      )}
    </Box>
  );
}

export default Notification;
