import img1 from 'assets/ads/01.webp';
import img2 from 'assets/ads/02.webp';
import img3 from 'assets/ads/03.webp';
import { links } from 'constants/common';

export const adsList = [
  {
    id: 1,
    title: 'колесо фортуны',
    img: img1,
    description: ``,
    button:{
        buttonColor: 'red',
        text: 'Перейти',
        isSensitive: true,
        redirect: '/wheel',
      } 
  },
  {
    id: 2,
    title: 'Наши Уровни',
    img: img2,
    description: ``,
    button:{ 
        type: 'openModal',
        modal: { modalName: 'levels', activeTab: '0' },
        isSensitive: true,
        buttonColor: 'red',
        text: 'Перейти',
        redirect: '',
      } 
  },
  { id: 3, title: 'Бонусы в телеграмме', img: img3, description: ``, 
     button:{
        type: 'external',
        buttonColor: 'red',
        text: 'Перейти',
        redirect: links.TG_CHANNEL,
      }, },
];
