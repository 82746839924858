import { Box, Button, Typography } from '@mui/material';
import common from 'styles/colors.module.scss';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import _color from 'styles/colors.module.scss';
import AnimatedCard from './card/card';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { PROCESS } from 'types/payment';
import { routing } from 'constants/routing';
import { usePayment } from 'hooks/payment/usePayment';
import SBP from 'assets/payment/sbp.png';
import LoaderWithBg from 'components/loader/loader';
import { useAuthContext } from 'context/auth/authContext';
import moment from 'moment';
import Dialogs from 'components/dialog/dialogs';
import { useTranslation } from 'react-i18next';
import { links } from 'constants/common';
import styles from './manualPayment.module.scss';

interface StaticCardProps {
  mobile?: string | null;
  amount?: number | null;
  account?: number | null;
  bank?: string | null;
}
const init = {
  amount: null,
  currency: null,
  paymentId: null,
  bankName: null,
  requisites: null,
  redirectToSupport: '',
};

const processTypeList: string[] = [PROCESS.CARD, PROCESS.PHONE, PROCESS.ACCOUNT];

const TEXT =
  'Вы уверены, что хотите отметить эту транзакцию как «Оплачено»? В случае совершения транзакций без оплаты администрация сайта заблокирует метод оплаты.';
const SUB_TEXT =
  'Внимание! Пожалуйста, будьте внимательны: отправляйте деньги на указанные реквизиты и в указанный БАНК, а затем нажмите «Я оплатил»';

function StaticCard({ mobile, amount, account, bank }: StaticCardProps) {
  return (
    <Box className={styles.card_number}>
      {mobile ? (
        <Box className={styles.row_title_block}>
          <Box className={styles.row_title}>
            <img src={SBP} width={60} />
          </Box>
          <Box className={styles.row_title}>
            <Typography component="p">К оплате:</Typography>
            <Typography component="p">{amount} ₽</Typography>
          </Box>
        </Box>
      ) : null}
      <Box className={styles.row}>
        <Typography component="h6">Отправьте</Typography>
        <Typography component="h3" sx={{ mt: 1 }}>
          {amount} ₽
          <ContentCopyIcon
            sx={{ ml: 1, fontSize: 18, cursor: 'pointer' }}
            onClick={() => {
              navigator.clipboard.writeText(`${amount ?? ''}`);
            }}
          />
        </Typography>
        <Typography component="span">
          Если Вы отправите неверную сумму - Ваши средства могут быть УТЕРЯНЫ!
        </Typography>
      </Box>
      <Box className={styles.row}>
        <Typography component="h6">{account ? `На счет` : `На номер`}</Typography>
        <Typography component="h3" sx={{ mt: 1 }}>
          {mobile ? mobile : account}
          <ContentCopyIcon
            sx={{ ml: 1, fontSize: 18, cursor: 'pointer' }}
            onClick={() => {
              navigator.clipboard.writeText(`${mobile ? mobile : account}`);
            }}
          />
        </Typography>
        <Typography component="span">
          Обработка платежа может занимать до 10 мин. Номер телефона должен быть точным. Иначе
          средство будут утеряны. Перевод выполнять строго через СБП
        </Typography>
      </Box>
      <Box className={styles.row}>
        <Typography component="h6">Банк</Typography>
        <Typography component="h3" sx={{ mt: 1 }}>
          {bank}
        </Typography>
      </Box>
    </Box>
  );
}

function ManualPayment() {
  const { t: commonT } = useTranslation('common');
  const params = useParams();
  const navigate = useNavigate();
  const { isLoading, payInProcess, payInProccesCancel } = usePayment();
  const [isOpen, setIsOpen] = useState(false);
  const [contentText, setContentText] = useState('');
  const [subText, setSubText] = useState('');
  const { hasAccessToken } = useAuthContext();
  const [type, setType] = useState('');
  const [hours, setHours] = useState('');
  const [data, setData] = useState({ ...init });

  let intervalId: ReturnType<typeof setInterval>;

  const countDown = (distance: number) => {
    let time = distance;
    intervalId = setInterval(() => {
      time = time - 1;
      if (time === 0) {
        setIsOpen(false);
        navigate(`${routing.home}`);
      }
      setHours(moment.utc(time * 1000).format('mm:ss'));
    }, 1000);
  };

  const context = {
    [PROCESS.CARD]: {
      leftPart: {
        mainTitle: `Пожалуйста, внимательно сверяйте номер карты и сумму, иначе платеж не будет
              зачислен`,
        title: 'Инструкция как оплатить',
        instruction: [
          `Переведите ${data.amount} ₽ по реквизитам из формы. Не переводите сумму больше или меньше 
   указанной суммы, так как при неверной сумме может оплатиться заявка другого 
   пользователя и средства будут утеряны.`,
          `Не переводите с ИП счетов на указанные реквизиты, так как данные платежи не
            обрабатываются нашей системой и средства не будут зачислены на баланс.`,
          `После оплаты нажмите «Я оплатил».`,
          `Заявка будет зачислена в течение 5 минут после перевода на указанные реквизиты.`,
        ],
      },
      rightPart: {
        title: `Оплата заказа #${data.paymentId}`,
        subTitle: `Переведите ${data.amount} ₽ на карту`,
      },
      component: <AnimatedCard cardNumber={data.requisites} />,
    },
    [PROCESS.PHONE]: {
      leftPart: {
        mainTitle: `Пожалуйста, внимательно сверяйте банк, сумму и реквизиты, иначе платеж не будет
              зачислен`,
        title: 'Инструкция как оплатить',
        instruction: [
          `Скопируйте реквизиты для перевода.`,
          `Скопируйте сумму и переводите одним платежом ровную сумму, иначе платеж не будет зачислен.`,
          `Внимание обязательно переводите СТРОГО на БАНК который мы указали.`,
          `После оплаты нажмите «Я оплатил» и ожидайте обработки платежа.`,
          `Заявка будет зачислена в течение 5 минут после перевода на указанные реквизиты.`,
        ],
      },
      rightPart: {
        title: `Оплата заказа #${data.paymentId}`,
        subTitle: `Переведите ${data.amount} ₽ через ${data.bankName}`,
      },
      component: <StaticCard mobile={data.requisites} amount={data.amount} bank={data.bankName} />,
    },
    [PROCESS.ACCOUNT]: {
      leftPart: {
        mainTitle: `Пожалуйста, внимательно сверяйте номер счета и сумму, иначе платеж не будет
              зачислен`,
        title: 'Инструкция как оплатить',
        instruction: [
          `Скопируйте реквизиты для перевода`,
          `Скопируйте сумму и переводите одним платежом ровную сумму, иначе платеж не будет зачислен`,
          `После оплаты нажмите «Я оплатил» и ожидайте обработки платежа.`,
          `Заявка будет зачислена в течение 5 минут после перевода на указанные реквизиты.`,
          `Сделайте скриншот формы оплаты.`,
        ],
      },
      rightPart: {
        title: `Оплата заказа #${data.paymentId}`,
        subTitle: `Переведите ${data.amount} ₽ на номер счета из верхней формы`,
      },
      component: <StaticCard amount={data.amount} account={data.requisites} />,
    },
  } as { [x: string]: any };

  const cancelPayment = async () => {
    const result = await payInProccesCancel(data.paymentId);
    if (result?.status === 200) {
      navigate(`${routing.home}`);
    }
  };

  const handleOnCLick = (event: React.MouseEvent<HTMLElement>, name: string) => {
    if (name === 'paymentConfirm') {
      setIsOpen(true);
      setContentText(TEXT);
      setSubText(SUB_TEXT);
    } else {
      cancelPayment();
    }
  };

  const redirectTo = () => {
    window.open(links.TG_SUPPORT, '_blank');
  };

  const getData = async (id: string, type: string) => {
    const result = await payInProcess(id);
    if (result?.status === 200) {
      setData((data) => ({ ...data, ...result.data }));
      if (result.data.ttl === null) {
        navigate(`${routing.home}`);
      } else {
        countDown(result.data.ttl);
        setType(type);
      }
    } else if (result?.status === 404) {
      navigate(`${routing.home}`);
    }
  };

  const handleConfirm = () => {
    navigate(`${routing.paymentProcessConfirm}`, {
      state: {
        ...data,
      },
    });
  };

  useEffect(() => {
    if (params) {
      const { id, type } = params;
      if (id && type && processTypeList.includes(type)) {
        if (hasAccessToken) getData(id, type);
      } else {
        navigate(`${routing.home}`);
      }
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [hasAccessToken]);

  return (
    <Box className={styles.content}>
      {isLoading && !hasAccessToken ? (
        <LoaderWithBg isOpen={isLoading && !hasAccessToken} />
      ) : data?.requisites ? (
        <>
          <Box className={styles.body}>
            <Box className={styles.col_left}>
              <Box className={styles.attention}>
                <Typography component="h1">{context?.[type]?.leftPart.mainTitle}</Typography>
              </Box>
              <Box className={styles.title}>
                <Typography component="h1">{context?.[type]?.leftPart.title}</Typography>
              </Box>
              {context?.[type]?.leftPart.instruction.map((el: any, i: number) => {
                return (
                  <Box sx={{ m: 1 }} key={i}>
                    {`${i + 1}.`} {el}
                  </Box>
                );
              })}
            </Box>
            <Box className={styles.col_right}>
              <Box className={styles.title}>
                <Typography component="h1">{context?.[type]?.rightPart.title}</Typography>
              </Box>
              <Box className={styles.card}>{context?.[type]?.component}</Box>
              <Box>
                <Typography
                  sx={{ color: common.error, fontWeight: 600, textAlign: 'center', m: 2 }}
                >
                  Оплатить нужно в течение {hours}
                </Typography>
              </Box>
              <Box className={styles.requirements}>
                {type === 'card' ? (
                  <Typography component="h3" sx={{ m: 1 }}>
                    {data.requisites}
                    <ContentCopyIcon
                      sx={{ ml: 1, fontSize: 18, cursor: 'pointer' }}
                      onClick={() => {
                        navigator.clipboard.writeText(data?.requisites ?? '');
                      }}
                    />
                  </Typography>
                ) : null}
              </Box>
              <Box className={styles.actions}>
                <Button
                  fullWidth
                  className={styles.green}
                  onClick={(e) => handleOnCLick(e, 'paymentConfirm')}
                >
                  Я оплатил
                </Button>
                <Button
                  fullWidth
                  className={styles.red}
                  onClick={(e) => handleOnCLick(e, 'cancelProcess')}
                >
                  Отменить платеж
                </Button>
                <Button fullWidth className={styles.grey} onClick={redirectTo}>
                  Написать в поддержку
                </Button>
              </Box>
            </Box>
          </Box>
          <Dialogs
            title={''}
            onConfirm={handleConfirm}
            content={contentText}
            subContent={subText}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            btnText={'Я оплатил'}
            ignoreBtnText={commonT('cancel')}
            contentStyle={{ padding: '0 12px' }}
          />
        </>
      ) : null}
    </Box>
  );
}

export default ManualPayment;
