import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import HomeIcon from '@mui/icons-material/Home';
import StreamIcon from '@mui/icons-material/Stream';
import BalanceIcon from '@mui/icons-material/Balance';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import LogoutIcon from '@mui/icons-material/Logout';
import { ReactComponent as WheelIcon } from 'assets/icons/menuIcons/wheel.svg';
import RecentActorsOutlinedIcon from '@mui/icons-material/RecentActorsOutlined';
import HistoryIcon from '@mui/icons-material/History';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import TelegramIcon from '@mui/icons-material/Telegram';
import { links } from 'constants/common';
import { routing } from 'constants/routing';

export const main_menu = [
  { id: '1', menu: 'lobby', link: '/', icon: <HomeIcon /> },
  { id: '2', menu: 'slots', link: '/slots', icon: <SportsBasketballIcon /> },
  { id: '3', menu: 'live', link: '/live', icon: <StreamIcon /> },
  { id: '4', menu: 'balance', link: '/balance', icon: <BalanceIcon /> },
  { id: '5', menu: 'bonuses', link: '/bonuses', icon: <WorkspacePremiumIcon /> },
  { id: '5', menu: 'myBonuses', link: '/myBonuses', icon: <CardGiftcardIcon /> },
  { id: '6', menu: 'statistics', link: '/statistics', icon: <QueryStatsIcon /> },
  { id: '7', menu: 'wheel', link: '/wheel', icon: <WheelIcon /> },
];

export const settings = [
  { id: '1', item: 'profile', modalType: 'profile', icon: RecentActorsOutlinedIcon },
  { id: '2', item: 'balanceTransfer', modalType: 'balance', icon: MonetizationOnOutlinedIcon },
  { id: '3', item: 'withDraw', modalType: 'withDraw', icon: CreditScoreIcon },
  { id: '4', item: 'userLevels', modalType: 'levels', icon: RocketLaunchIcon },
  { id: '5', item: 'claims', modalType: 'claims', icon: MailOutlineIcon },
  { id: '6', item: 'gameHistory', modalType: 'history', icon: HistoryIcon },
  { id: '7', item: 'notification', modalType: 'notification', icon: NotificationsNoneOutlinedIcon },
  { id: '6', item: 'onlineChat', modalType: '', link: links.TAWK, icon: SupportAgentIcon },
  { id: '7', item: 'tgSupport', modalType: '', link: links.TG_MANAGER, icon: TelegramIcon },
  { id: '8', item: 'logOut', modalType: 'logout', icon: LogoutIcon },
];

export const moke_data = [
  {
    id: '1',
    menu: 'help',
    link: '',
    subMenu: [
      { id: '1', link: '/', menu: 'support' },
      { id: '1', link: '/faq', menu: 'faq' },
    ],
  },
  {
    id: '2',
    menu: 'info',
    link: '',
    subMenu: [
      { id: '2', link: routing.payment, menu: 'payment' },
      { id: '2', link: routing.terms, menu: 'termsAndConditions' },
      { id: '2', link: routing.bonusTerms, menu: 'bonusTermsAndConditions' },
      { id: '2', link: routing.aboutUs, menu: 'aboutUs' },
    ],
  },
];
export const moke_data_mobile = [
  {
    id: '1',
    menu: 'help',
    link: '',
    subMenu: [
      {
        id: '1',
        link: links.TAWK,
        menu: 'onlineChat',
      },
      { id: '1', link: links.TG_MANAGER, menu: 'tgSupport' },
      { id: '1', link: '/faq', menu: 'faq' },
    ],
  },
  {
    id: '2',
    menu: 'info',
    link: '',
    subMenu: [
      { id: '2', link: routing.payment, menu: 'payment' },
      { id: '2', link: routing.terms, menu: 'termsAndConditions' },
      { id: '2', link: routing.bonusTerms, menu: 'bonusTermsAndConditions' },
      { id: '2', link: routing.aboutUs, menu: 'aboutUs' },
    ],
  },
];

export const support_menu_mobile = [
  {
    id: '1',
    link: links.TAWK,
    menu: 'onlineChat',
  },
  { id: '2', link: links.TG_SUPPORT, menu: 'tgSupport' },
  { id: '3', link: links.TG_MANAGER, menu: 'tgManager' },
];
