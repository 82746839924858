import { ModalState } from 'context/modal/modalContext';
import ModalContent from 'components/modals/modalContent/modalContent';
import Verification from '../../verification/verification';
import SecurityTab from 'components/security/security';
import useVerification from 'hooks/user/useVerification';
import { useSelector } from 'react-redux';
import UserInfo from 'components/userInfo/userInfo';

interface DepositModalProps {
  onClose?: (type: string) => void;
  props?: ModalState;
}

export interface ITabs {
  id: string;
  name: string;
}

export interface IChildren {
  component: React.ReactNode;
}

const tabMenu = [
  {
    id: '1',
    name: 'information',
  },
  // { id: '2', name: 'verification' },
  // { id: '3', name: 'security' },
];

function ProfileModal({ onClose, props }: DepositModalProps) {
  const userData = useSelector<any>((state) => state.userData) as any;
  const id = userData?.id;
  useVerification(id);

  const children = [
    { component: <UserInfo /> },
    // { component: <Verification /> },
    // { component: <SecurityTab /> },
  ];

  return (
    <ModalContent
      onClose={onClose}
      props={props}
      tabContent={children}
      tabMenu={tabMenu}
      translateFrom={'profile'}
    />
  );
}

export default ProfileModal;
