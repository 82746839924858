import Card from '@mui/material/Card';
import cx from 'classnames';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'context/auth/authContext';
import { useModalContext } from 'context/modal/modalContext';
import { FIELD_NAME } from 'types/login';
import styles from './adsCard.module.scss';
import { IButtonAds } from 'types/game';

interface AdsCardProps {
  imageUrl: string;
  button: IButtonAds;
  className?: string;
}

export default function AdsCard({ imageUrl, button, className }: AdsCardProps) {
  const navigate = useNavigate();
  const { openModalWithData } = useModalContext();
  const { user } = useAuthContext();

  const handleOnClick = (data: IButtonAds) => {
    if (data?.type === 'external') {
      window.open(data?.redirect ?? '', '_blank');
    } else if (data?.isSensitive) {
      if (!user) {
        openModalWithData(
          FIELD_NAME.LOGIN,
          data?.redirect
            ? {
                redirect: data?.redirect,
              }
            : data?.type === 'openModal'
              ? { isOpenModal: true, ...data?.modal }
              : null,
        );
      } else {
        if (data?.type === 'openModal') {
          openModalWithData(data?.modal?.modalName ?? '', { activeTab: data?.modal?.activeTab });
        } else {
          const state = data
            ? {
                state: {
                  ...data,
                },
              }
            : {};
          navigate(data?.redirect ?? '', state);
        }
      }
    } else {
      const state = data
        ? {
            state: {
              ...data,
            },
          }
        : {};
      navigate(data?.redirect ?? '', state);
    }
  };
  return (
    <Box className={cx(styles.box_card, className)}>
      <Card className={styles.card}>
        <Box className={styles.img}>
          <img src={imageUrl} alt="game" width="auto" />
          <Box className={styles.btn}>
            <Button size="small" className="App-btn red" onClick={() => handleOnClick(button)}>
              {button.text}
            </Button>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}
