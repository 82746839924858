// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Navigation, Pagination, Autoplay, A11y } from 'swiper/modules';
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import GameCard from 'components/gameCard/gameCard';
import { ICarouselItems } from 'types/game';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import _color from 'styles/colors.module.scss';
import { deviceSize } from 'constants/common';
import styles from './carousel.module.scss';

interface CarouselProps {
  width?: stirng;
  carouselItems?: ICarouselItems[];
  isLoading?: boolean;
}

const breakPoints = {
  320: {
    slidesPerView: 2,
  },
  640: {
    slidesPerView: 4,
  },
  768: {
    slidesPerView: 5,
  },

  1200: {
    slidesPerView: 5,
  },
  3000: {
    slidesPerView: 5,
  },
};

export function Carousel({ width, carouselItems, isLoading }: CarouselProps) {
  const { device } = useSelector<any>((state) => state.device) as {
    device: string;
  };
  const spaceBetween = device !== deviceSize.DESKTOP ? -7 : 8;
  return (
    <>
      {isLoading ? (
        <Stack sx={{ color: 'grey.500', justifyContent: 'center' }} spacing={2} direction="row">
          <CircularProgress sx={{ color: _color.mainOrange }} />
        </Stack>
      ) : (
        <Swiper
          modules={[Autoplay, Navigation, Pagination, A11y]}
          spaceBetween={spaceBetween}
          slidesPerView={'auto'}
          scrollbar={{ draggable: true }}
          loop={true}
          className={styles.sliderWrapper}
          style={{ width: '100%' }}
        >
          {carouselItems?.map(({ image, id, providerId, name, isFavorite, isUnliked }, index) => {
            return (
              <SwiperSlide key={index}>
                <GameCard
                  imageUrl={image}
                  gameId={id}
                  providerId={providerId}
                  gameName={name}
                  isFavorite={isFavorite}
                  isUnliked={isUnliked}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </>
  );
}
