import { Box, Typography } from '@mui/material';
import WheelComponent from './wheel/wheel';
import { useSelector } from 'react-redux';
import AlertDialog from 'components/alert/dialog/dialog';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dialog } from 'constants/common';
import animationData from './coins.json';
import moment from 'moment';
import { useUserData } from 'hooks/user/useUserData';
import LoaderWithLogo from 'components/loader/loaderWithLogo';
import Lottie from 'react-lottie';
import { routing } from 'constants/routing';
import styles from './wheel.module.scss';

export const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const WHEEL_TYPE = { SPINNED: 'spinned', WAITING: 'waiting', DISABLED: 'disabled' };

export const WheelPage = () => {
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  const [hours, setHours] = useState('');
  const [type, setType] = useState<string | undefined>();
  const [bonus, setBonus] = useState<string | undefined>();
  const [result, setResult] = useState<any>();

  let intervalId: ReturnType<typeof setInterval>;
  const { isLoading, getUserWheelDiff } = useUserData();

  const { wheelValues } = useSelector<any>((state) => state.siteConfigs) as { [x: string]: any };
  const userData = useSelector<any>((state) => state.userData) as any;

  const getData = async () => {
    const result = await getUserWheelDiff();
    if (result) {
      if (result?.data?.spinDiff === null) {
        setType('');
      } else {
        setType(WHEEL_TYPE.WAITING);
        countDown(result?.data?.spinDiff);
      }
    }
  };

  const handleToggleDialog = (data: boolean, type?: string, result?: any) => {
    setOpen(data);
    if (result?.spinDiff) {
      if (type === WHEEL_TYPE.SPINNED) {
        countDown(result?.spinDiff);
      }
    }

    setBonus(result?.value);
    setResult(result ?? null);
    setType(type ?? '');
  };

  const countDown = (distance: number) => {
    let time = distance;
    intervalId = setInterval(() => {
      time = time - 1;
      if (time === 0) {
        setType('');
        clearInterval(intervalId);
        getData();
      }
      setHours(moment.utc(time * 1000).format('HH:mm:ss'));
    }, 1000);
  };

  useEffect(() => {
    if (type !== WHEEL_TYPE.WAITING && type !== '') {
      getData();
    }

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <Box className={styles.main}>
      {isLoading ? (
        <LoaderWithLogo isOpen={isLoading} />
      ) : (
        <>
          {(type === WHEEL_TYPE.WAITING || type === WHEEL_TYPE.SPINNED) &&
          userData.isWheelEnabled &&
          hours ? (
            <>
              <Box className={styles.delay_text}>
                <Typography component={'h2'}>{t('notAvailableYet')}</Typography>
              </Box>

              <Typography className={styles.delay} component={'p'}>
                {hours}
              </Typography>
            </>
          ) : null}

          <WheelComponent
            isClickAble={
              userData.isWheelEnabled && userData.spinDiff === null && type !== WHEEL_TYPE.WAITING
            }
            items={wheelValues ?? []}
            openDialog={handleToggleDialog}
          />
          {open && type === WHEEL_TYPE.SPINNED ? (
            <>
              <Lottie
                style={{
                  position: 'absolute',
                  zIndex: 10000,
                  height: '300px',
                  width: '300px',
                }}
                options={defaultOptions}
              />
              <Lottie
                style={{
                  position: 'absolute',
                  height: '100vh',
                  width: '100vw',
                }}
                options={defaultOptions}
              />
            </>
          ) : null}
          {open ? (
            <AlertDialog
              type={type ?? ''}
              bonus={bonus}
              result={result}
              title={
                type === WHEEL_TYPE.SPINNED ? t(dialog.WHEEL_TITLE_WIN) : t(dialog.WHEEL_TITLE)
              }
              context={
                type === WHEEL_TYPE.SPINNED ? t(dialog.WHEEL_CONTENT_WIN) : t(dialog.WHEEL_CONTENT)
              }
              btnText={type === WHEEL_TYPE.SPINNED ? t('takeYourWin') : t('ok')}
              isOpen={open}
              closeDialog={handleToggleDialog}
              link={{ to: routing.bonusTerms, text: t('reedTerms') }}
            />
          ) : null}
        </>
      )}
    </Box>
  );
};
