import { Navigation, Pagination, Autoplay, A11y } from 'swiper/modules';
import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { IAds } from 'types/game';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import AdsCard from 'components/adsCard/adsCard';
import styles from './adsCarousel.module.scss';
import { useEffect } from 'react';

interface AdsCarouselProps {
  carouselItems: IAds[];
}

export function AdsCarousel({ carouselItems }: AdsCarouselProps) {
  const [windowSize, setWindowSize] = useState('');

  useEffect(() => {
    setWindowSize(window.innerWidth + '');
  }, []);

  return (
    <Swiper
      modules={[Autoplay, Navigation, Pagination, A11y]}
      className={styles.sliderWrapper}
      spaceBetween={0}
      slidesPerView={'auto'}
      scrollbar={{ draggable: true }}
      loop={true}
      threshold={10}
      touchRatio={1}
      style={{ width: '100%' }}
      allowTouchMove={windowSize <= '900'}
    >
      {carouselItems?.map(({ img, button }, index) => {
        return (
          <SwiperSlide key={index}>
            <AdsCard imageUrl={img} button={button} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}
