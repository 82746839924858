import http2 from 'http2-common';

class GameStatisticsDataService {

    
  getLifeTopWinners = (headers, query = '') => {
    return http2.get(`/games/statistic/top-winners${query}`, { headers });
  };
  
  getTopWinsGames = (headers) => {
    return http2.get(`/games/statistic/top-win?&count=20&sortBy=winAmount&sortDir=DESC`, { headers });
  };

  getTopWinsLast24Games = (headers) => {
    return http2.get(`/games/statistic/top-win-24?&count=20&sortBy=winAmount&sortDir=DESC`, { headers });
  };
  
  getPopularGames = (headers) => {
    return http2.get(`/games/statistic/popular?count=20`, { headers });
  };

  getUnlikedGames = (headers) => {
    return http2.get(`/games/statistic/unliked?count=20`, { headers });
  };
  
}

const GameStatisticsService = new GameStatisticsDataService();

export default GameStatisticsService;
