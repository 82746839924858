import { Box, CircularProgress, Pagination, Stack, Tab, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import moment from 'moment';
import cx from 'classnames';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import DescriptionIcon from '@mui/icons-material/Description';
import { useTranslation } from 'react-i18next';
import { ITabs } from 'components/modals/depositModal/depositModal';
import { useTransaction } from 'hooks/transactions/useTransaction';
import { useBalance } from 'hooks/user/useBalance';
import { useStyles } from 'components/pageLayout/category/category';
import { useSelector } from 'react-redux';
import { isBoolean } from 'utils/utils';
import { HISTORY_ITEM } from 'types/modals';
import styles from './history.module.scss';

type ITabName = keyof typeof helperObject;

interface HistoryProps {
  historyTabMenu: ITabs[];
}

const helperObject = {
  [HISTORY_ITEM.BONUS]: ['amount', 'name', 'weiger', 'status', 'createdAt'],
  [HISTORY_ITEM.TRANSFER]: ['amount', 'fromBalance', 'toBalance', 'createdAt'],
  [HISTORY_ITEM.WITHDRAW]: ['amount', 'status', 'method', 'createdAt'],
  [HISTORY_ITEM.DEPOSIT]: ['amount', 'status', 'method', 'createdAt'],
};

function renderTab(historyList: any, t: any, code: string, tabName: ITabName): any {
  return (
    <>
      <Box className={styles.history_item_title}>
        {helperObject[tabName].map((elm, i) => {
          return <Typography key={i}>{t(elm)}</Typography>;
        })}
      </Box>

      {historyList.map((item: any, index: number) => {
        return (
          <Box key={index} className={styles.history_item}>
            {helperObject[tabName].map((elm, i) => {
              return (
                <Typography
                  key={i}
                  className={cx({
                    [styles.pending]:
                      elm === 'status' && (item.status === 'pending' || item.status === 'active'),
                    [styles.completed]:
                      elm === 'status' && (item.status === 'approved' || item.status === 'new'),
                    [styles.approved]: elm === 'status' && item.status === 'completed',
                    [styles.failed]: elm === 'status' && item.status === 'failed',
                    [styles.returned]: elm === 'status' && item.status === 'canceled_returned',
                    [styles.canceled]:
                      elm === 'status' && (item.status === 'canceled' || item.status === 'used'),
                  })}
                >
                  {elm === 'createdAt'
                    ? `${moment(item?.[elm] ?? item.date).format('DD/MM/Y HH:mm:ss')}`
                    : isBoolean(item?.[elm])
                      ? item?.[elm]
                        ? t('yes')
                        : t('no')
                      : elm === 'amount'
                        ? `${t(item?.[elm])} ${code}`
                        : String(item?.[elm]).includes('WHEEL_BONUS[') ||
                            String(item?.[elm]).includes('Wheel Bonus [')
                          ? t('wheel')
                          : `${t(item?.[elm])}`}
                </Typography>
              );
            })}
          </Box>
        );
      })}
    </>
  );
}

function History({ historyTabMenu }: HistoryProps) {
  const { t: commonT } = useTranslation('common');
  const { t } = useTranslation('deposit');
  const [value, setValue] = useState<string>('0');
  const [historyList, setHistoryList] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [page, setPage] = useState(1);
  const classes = useStyles();
  const {
    isLoading,
    getAllDepositTransactions,
    getAllWithdrawTransactions,
    getAllBonusTransactions,
  } = useTransaction();
  const { isLoading: isLoadingBalanceСhanges, getUserBalanceChangesAll } = useBalance();

  const userData = useSelector<any>((state) => state.userData) as any;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue + '');
    setHistoryList([]);
    setPage(1);
    setTotalPages(null);
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    if (historyTabMenu.length) {
      const paramsQuery = `page=${page}&pageSize=15`;
      let res: any;
      const fetchData = async (type: string) => {
        switch (type) {
          case HISTORY_ITEM.BONUS:
            res = await getAllBonusTransactions(paramsQuery);
            break;
          case HISTORY_ITEM.TRANSFER:
            res = await getUserBalanceChangesAll(paramsQuery);
            break;
          case HISTORY_ITEM.DEPOSIT:
            res = await getAllDepositTransactions(paramsQuery + '&isBonus=false');
            break;
          case HISTORY_ITEM.WITHDRAW:
            res = await getAllWithdrawTransactions(paramsQuery);
            break;
          default:
            null;
        }
        if (res) {
          setHistoryList(res.data);
          setTotalPages(res.totalPages);
        }
      };

      fetchData(historyTabMenu[+value].name);
    }
  }, [historyTabMenu, value, page]);

  return (
    <Box className={styles.main_area}>
      <Box className={styles.main_title}>{t('history')}</Box>
      <Box className={styles.methods_block}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              {historyTabMenu.map(({ name }, index) => {
                return (
                  <Tab
                    key={index}
                    value={index + ''}
                    label={t(name)}
                    sx={{ alignItems: 'start' }}
                  />
                );
              })}
            </TabList>
          </Box>
          <TabPanel value={value}>
            <Box className={styles.history_block}>
              {isLoading || isLoadingBalanceСhanges ? (
                <Stack
                  sx={{ minHeight: '520px', color: 'grey.500', justifyContent: 'center' }}
                  spacing={2}
                  direction="row"
                >
                  <CircularProgress color="warning" />
                </Stack>
              ) : (
                <Box className={styles.history_block_lists}>
                  {!historyList.length ? (
                    <Box className={styles.empty}>
                      <DescriptionIcon />
                      <Typography component="span">{t('emptyHistory')}</Typography>
                    </Box>
                  ) : (
                    <>
                      {renderTab(
                        historyList,
                        commonT,
                        userData?.currency?.code,
                        historyTabMenu[+value].name as ITabName,
                      )}
                    </>
                  )}
                </Box>
              )}
              {(!isLoading || !isLoadingBalanceСhanges) && totalPages && totalPages > 1 ? (
                <Stack spacing={2} sx={{ m: 4 }}>
                  <Pagination
                    count={totalPages}
                    className={classes.ul}
                    hideNextButton
                    hidePrevButton
                    page={page}
                    onChange={handleChangePage}
                  />
                </Stack>
              ) : null}
            </Box>
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
}

export default History;
