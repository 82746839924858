export enum FIELD_NAME_GAME {
  GAME_SESSION_NEED_OPEN_GAME = 'gameSessionNeedOpenGame',
  GAME_SESSION = 'gameSession',
}

export interface IGame extends ICarouselItems {
  id: number;
}

export interface ICarouselItems {
  id: string | number;
  image: string;
  providerId: number;
  name: string;
  isFavorite?: boolean;
  isUnliked?: boolean;
}

export interface IAds {
  id: string | number;
  img: string;
  title: string;
  description?: string;
  button: IButtonAds;
}

export interface IButtonAds {
  type?: string;
  modal?: { modalName: string; activeTab: string };
  isSensitive?: boolean;
  buttonColor: string;
  text: string;
  redirect?: string;
}

export interface IProviderGame {
  id: string;
  image: string;
  /**TODO: write real types after getting all final data from BE */
  [x: string]: string | number;
}
