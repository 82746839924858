import { useApiErrors } from 'hooks/errors/useApiErrors';
import { useStorage } from 'hooks/authHooks/useStorage';
import { useState } from 'react';
import { generateHeaders } from 'utils/utils';
import GameStatisticsService from 'services/gameStatistics.service';
import { useUser } from 'hooks/user/useUser';
import { setTopWinners } from 'redux/actions/topWinnersActions';
import { useDispatch } from 'react-redux';

export const useStatistics = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { removeUser } = useUser();
  const [error, setError] = useState('');
  const { userToken } = useStorage();
  const dispatch = useDispatch();
  const { errorHandler } = useApiErrors();
  const initHeaders = generateHeaders(userToken());

  const getPopularGames: any = async (headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await GameStatisticsService.getPopularGames(headers);

      return { status, data };
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { message, status },
          },
        } = err;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await getPopularGames(newHeader);
        } else {
          console.log('LOG____> playGame', message);
          setError(message);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getUnlikedGames: any = async (headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await GameStatisticsService.getUnlikedGames(headers);

      return { status, data };
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { message, status },
          },
        } = err;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await getUnlikedGames(newHeader);
        } else {
          console.log('LOG____> getUnlikedGames', message);
          setError(message);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getTopWinsGames: any = async (headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await GameStatisticsService.getTopWinsGames(headers);
      if (status === 200) {
        return { status, data };
      }
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { message, status },
          },
        } = err;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await getTopWinsGames(newHeader);
        } else {
          console.log('LOG____> playGame', message);
          setError(message);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getTopWinsLast24Games: any = async (headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await GameStatisticsService.getTopWinsLast24Games(headers);

      return { status, data };
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { message, status },
          },
        } = err;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await getTopWinsLast24Games(newHeader);
        } else {
          console.log('LOG____> playGame', message);
          setError(message);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getLifeTopWinners: any = async (query?: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await GameStatisticsService.getLifeTopWinners(headers, query);
      if (status === 200) {
        dispatch(setTopWinners(Object.values(data)));
        return { status, data };
      }
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { message, status },
          },
        } = err;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await getLifeTopWinners(query, newHeader);
        } else {
          console.log('LOG____> playGame', message);
          setError(message);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    getPopularGames,
    getLifeTopWinners,
    getTopWinsGames,
    getTopWinsLast24Games,
    getUnlikedGames,
    error,
  };
};
