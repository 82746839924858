import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NotificationItem from '../notificationItem';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import { useNotification } from 'hooks/user/useNotifications';
import { useStorage } from 'hooks/authHooks/useStorage';
import { useDispatch } from 'react-redux';
import { userMessagesSeen } from 'redux/actions/userDataAction';
import { INotificationItem } from 'types/notification';
import styles from './messages.module.scss';

interface MessagesProps {
  onShowMoreExternal?: () => void;
  notificationList: INotificationItem[];
  classNames?: string;
  wrapperClassName?: string;
  forAllNotifications?: boolean;
}
const body: any = {
  /**TODO: write real types when backend will be ready**/
  messages: [] as unknown,
};

function Messages({
  notificationList,
  classNames,
  wrapperClassName,
  forAllNotifications,
  onShowMoreExternal,
}: MessagesProps) {
  const { readUserData, updateData } = useStorage();
  const { getUserMessages, totalPages, forUserMessagesSeen } = useNotification();
  const { t } = useTranslation('common');
  const [allNotification, setAllNotification] = useState([]);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleOnClickMore = (event: React.ChangeEvent<unknown>) => {
    if (onShowMoreExternal) {
      onShowMoreExternal();
    } else {
      setPage((value) => (value + 1 <= totalPages ? value + 1 : value));
    }
  };

  const userSeen = async () => {
    const result = await forUserMessagesSeen(body);

    if (result) {
      const user = readUserData();
      const notificationCount = user?.user?.messages?.count;
      const lastMessages = user?.user?.messages?.lastMessages;
      if (notificationCount > 0) {
        dispatch(userMessagesSeen(body.messages));
        updateData({
          messages: {
            count: `${Number(notificationCount) - body.messages.length}`,
            lastMessages: lastMessages?.filter((elm: any) => !body.messages.includes(elm.id)),
          },
        });
      }

      body.messages = [];
    }
  };

  useEffect(() => {
    const getData = async () => {
      const query = `?page=${page}`;
      const result = await getUserMessages(query);

      if (result) {
        if (result.data.totalPages && result.data.totalPages > 1) {
          setAllNotification((allNotification) =>
            allNotification
              ? [...allNotification, ...result.data.userMessages]
              : result.data.userMessages,
          );
        } else {
          setAllNotification(result.data.userMessages);
        }
        result.data.userMessages?.map((elm: any) => {
          if (!elm.isViewed && !body.messages.includes(elm.id)) body.messages.push(elm.id);
        });
        if (body.messages.length) userSeen();
      }
    };

    if (forAllNotifications) getData();
  }, [page, forAllNotifications]);

  return (
    <Box className={cx(styles.main, wrapperClassName)}>
      <Box className={cx(styles.notifications_list, classNames)}>
        {!forAllNotifications && notificationList ? (
          notificationList.map((item, index) => {
            return index < 5 ? (
              <NotificationItem type="message" item={item} index={index} key={index} />
            ) : null;
          })
        ) : allNotification.length ? (
          allNotification.map((item, index) => {
            return <NotificationItem type="message" item={item} index={index} key={index} />;
          })
        ) : (
          <Typography>{t('noNotification')}</Typography>
        )}
      </Box>
      {((totalPages > 0 && page < totalPages) || !forAllNotifications) && (
        <Button sx={{ mt: 3 }} fullWidth variant="contained" onClick={handleOnClickMore}>
          {t('showMore')}
        </Button>
      )}
    </Box>
  );
}

export default Messages;
