import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { activeTab } from 'components/navBar/navBar';
import { useModalContext } from 'context/modal/modalContext';
import { MODAL_TYPES } from 'types/modals';
import styles from './paymentsItem.module.scss';

interface PaymentsItemItemProps {
  translaste: string;
  isMobile?: boolean;
  rowsPayOut: any[];
  rowsPayIn: any[];
}

function PaymentsItem({ translaste, rowsPayOut, rowsPayIn }: PaymentsItemItemProps) {
  const { t } = useTranslation(translaste);
  const { t: depositT } = useTranslation('deposit');
  const { openModalWithData } = useModalContext();

  const renderTitles = () => {
    return (
      <>
        <Typography>{t('type')}</Typography>
        <Typography>{t('commission')}</Typography>
        <Typography>{t('processing')}</Typography>
        <Typography>{t('limit')}</Typography>
      </>
    );
  };

  const handleOpenDeposit = () => {
    openModalWithData(MODAL_TYPES.DEPOSIT, { activeTab: activeTab['deposit'] });
  };

  const handleOpenWithDraw = () => {
    openModalWithData(MODAL_TYPES.DEPOSIT, { activeTab: activeTab['withDraw'] });
  };

  return (
    <Box className={styles.content}>
      <Box sx={{ mb: 2 }}>
        <Typography component="span">{depositT('deposit')}</Typography>
      </Box>
      {rowsPayIn.length
        ? rowsPayIn?.map((row: any, index: number) => {
            return (
              <Box key={index} className={styles.item}>
                <Box className={styles.header}>
                  <Box className={styles.img}>
                    <img src={row.image} alt="card" width={65} />
                  </Box>
                  <Box className={styles.btn}>
                    <Button
                      sx={{ ml: 2, minWidth: '110px' }}
                      variant="contained"
                      onClick={handleOpenDeposit}
                    >
                      {depositT('deposit')}
                    </Button>
                  </Box>
                </Box>
                <Box className={styles.body}>
                  <Box className={styles.col_left}>{renderTitles()}</Box>
                  <Box className={styles.col_right}>
                    <Typography>{row.name}</Typography>
                    <Typography>{t('free')}</Typography>
                    <Typography>{t('timePayIn')}</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        {depositT('min')} {row.sitePayInMethods[0].minValue}{' '}
                        {row.sitePayInMethods[0].currencyCode}
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        {depositT('max')} {row.sitePayInMethods[0].maxValue}{' '}
                        {row.sitePayInMethods[0].currencyCode}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            );
          })
        : null}
      <Box sx={{ mb: 2, mt: 2 }}>
        <Typography component="span">{depositT('withdrawFunds')}</Typography>
      </Box>
      {rowsPayOut.length
        ? rowsPayOut?.map((row: any, index: number) => {
            return (
              <Box key={index} className={styles.item}>
                <Box className={styles.header}>
                  <Box className={styles.img}>
                    <img src={row.image} alt="card" width={65} />
                  </Box>
                  <Box className={styles.btn_out}>
                    <Button
                      sx={{ ml: 2, minWidth: '110px' }}
                      variant="contained"
                      onClick={handleOpenWithDraw}
                    >
                      {depositT('withdrawFunds')}
                    </Button>
                  </Box>
                </Box>
                <Box className={styles.body}>
                  <Box className={styles.col_left}>{renderTitles()}</Box>
                  <Box className={styles.col_right}>
                    <Typography>{row.name}</Typography>
                    <Typography>{t('free')}</Typography>
                    <Typography>{t('timePayOut')}</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        {depositT('min')} {row.sitePayOutMethods[0].minValue}{' '}
                        {row.sitePayOutMethods[0].currencyCode}
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        {depositT('max')}
                        {row.sitePayOutMethods[0].maxValue} {row.sitePayOutMethods[0].currencyCode}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            );
          })
        : null}
    </Box>
  );
}

export default PaymentsItem;
