import { useEffect, useState } from 'react';
import stone from 'assets/wheel/stone.png';
import logo from 'assets/wheel/logo_for_wheel.png';
import cx from 'classnames';
import { Box, Typography } from '@mui/material';
import { useUserData } from 'hooks/user/useUserData';
import { useSelector } from 'react-redux';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { useModalContext } from 'context/modal/modalContext';
import { WHEEL_TYPE } from '../wheel';
import styles from './wheel.module.scss';

interface WheelComponentProps {
  items: string[];
  onSelectItem?: any;
  isClickAble?: boolean;
  openDialog?: (state: boolean, type: string, result?: any) => void;
}

export function playAudio(url: string) {
  return new Audio(url);
}

export default function WheelComponent({ items, openDialog, isClickAble }: WheelComponentProps) {
  let timeOutId: ReturnType<typeof setTimeout>;
  const { isModalOpened } = useModalContext();
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [wheelVars, setWheelVars] = useState<any>({
    '--nb-item': items.length,
    '--selected-item': selectedItem,
  });
  const [spinning, setSpinning] = useState<any>('');
  const { isLoading, getUserWheelBonus } = useUserData();

  const pathSpinSound = './spin.mp3';
  const spinSound = playAudio(pathSpinSound);

  const userData = useSelector<any>((state) => state.userData) as any;

  const selectItem = async () => {
    if (userData.isWheelEnabled) {
      if (selectedItem === null && isClickAble && !isLoading) {
        const result = await getUserWheelBonus();
        if (result) {
          const index = items.findIndex((el) => el === result.data?.value);
          if (index !== -1) {
            setSelectedItem(index ?? null);
            timeOutId = setTimeout(() => {
              if (result?.data?.isWheelEnabled && result.data?.spinDiff === null) {
                setSpinning('');
                setSelectedItem(null);
              }
              openDialog?.(true, WHEEL_TYPE.SPINNED, result.data);
            }, 4500);
          } else {
            openDialog?.(true, WHEEL_TYPE.DISABLED);
          }
        } else {
          setSelectedItem(null);
        }
        /**TODO  need in future */
        // if (onSelectItem) {
        //   onSelectItem(selectedItem);
        // }
      }
    } else {
      openDialog?.(true, WHEEL_TYPE.DISABLED);
    }
  };

  useEffect(() => {
    setWheelVars((data: any) => ({
      ...data,
      '--nb-item': items.length,
      '--selected-item': selectedItem,
    }));
    setSpinning(selectedItem !== null ? 'spinning' : '');
    if (selectedItem && !isModalOpened) {
      spinSound.play();
    }

    return () => {
      clearTimeout(timeOutId);
      spinSound.pause();
    };
  }, [selectedItem, items]);

  return (
    <>
      <Box
        className={cx(styles.start, { [styles.clicked]: selectedItem })}
        onClick={!spinning ? selectItem : () => null}
      >
        <PlayCircleIcon />
      </Box>
      <Box className={styles.wheel_container}>
        <img src={logo} className={styles.logo} alt="loogo" width={65} />
        <Box className={cx(styles.wheel, { [styles.spinning]: spinning })} style={{ ...wheelVars }}>
          {items.map((item, index) => (
            <Box className={styles.wheel_item} key={index} style={{ '--item-nb': index } as any}>
              <img src={stone} className={styles.stone} alt="stone" width={65} />
              <Typography component="span" className={styles.bonus_text}>
                {item}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
}
