import cx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Button, Card, CardContent, CardMedia, Typography } from '@mui/material';
// import { ChevronRightRounded } from '@mui/icons-material';
import styles from './bonusCard.module.scss';
import { useTranslation } from 'react-i18next';
import { useModalContext } from 'context/modal/modalContext';
import { modalTypesObj } from 'components/navBar/navBar';

interface IButtonContent {
  name: string;
  link: string;
  buttonText: string;
}

interface BonusCardProps {
  image: string;
  title?: string;
  buttonContent?: IButtonContent;
  content: string;
}

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 30,
    boxShadow: 'none',
    transition: '0.3s cubic-bezier(0.47, 1.64, 0.41, 0.8)',
    '&:hover': {
      transform: 'scale(1.06)',
      boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.12)',
    },
  },
  content: {
    padding: 24,
  },
  cta: {
    textTransform: 'initial',
    fontSize: '0.8rem',
  },
}));

export default function BonusCard({ image, title, content, buttonContent }: BonusCardProps) {
  const classes = useStyles();
  const { openModalWithData } = useModalContext();
  const { t } = useTranslation('common');

  const handleClick = (data: IButtonContent) => {
    if (data.name === 'levels') {
      openModalWithData(modalTypesObj[data.name], null);
    } else if (data.name === 'email') {
      window.location.href = `mailto:${data.link}`;
    } else {
      window.location.href = data.link;
    }
  };

  return (
    <Card className={cx(classes.root, styles.card)}>
      <CardMedia sx={{ width: '100%', height: '220px' }} image={image} />
      <CardContent className={cx(classes.content)}>
        <Typography className={styles.title} component={'h4'} textAlign={'center'}>
          {title}
        </Typography>
        <div
          className={styles.content_text}
          dangerouslySetInnerHTML={{ __html: content ? content : '' }}
        ></div>
        {buttonContent && (
          <Button
            color={'primary'}
            onClick={() => handleClick(buttonContent)}
            fullWidth
            className={classes.cta}
          >
            {t(buttonContent.buttonText)}
          </Button>
        )}
      </CardContent>
    </Card>
  );
}
