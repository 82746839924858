import http2 from '../http2-common';

class PaymentDataService {
  payIn = (headers, methodId, body) => {
    return http2.post(`/finances/pay-in/${methodId}/process`, body, { headers });
  };

  payOut = (headers, methodId, body) => {
    return http2.post(`/finances/pay-out/${methodId}/process`, body, { headers });
  };

  payInProcess = (headers, id) => {
    return http2.get(`/finances/pay-in/process/${id}/details`, { headers });
  };

  payInProccesCancel = (headers, id) => {
    return http2.post(`/finances/pay-in/process/${id}/details/cancel`, {}, { headers });
  };

  payInProccesConfirm = (headers, id) => {
    return http2.get(`/finances/pay-in/process/${id}/details/check`, { headers });
  };
}

const PaymentService = new PaymentDataService();

export default PaymentService;
