export enum FIELD_NAME {
  CARD = 'card',
  MIN = 'min',
  BONUS = 'bonus',
  GAME = 'game',
  WALLET = 'wallet',
  FROM = 'from',
  TO = 'to',
  AMOUNT = 'amount',
  PHONE = 'phone',
  BANK_LIST = 'bankList',
  TRANSFER_AMOUNT = 'transferAmount',
}

export enum PROCESS {
  CARD = 'card',
  PHONE = 'phone',
  ACCOUNT = 'account',
}

export interface IPayCurrency {
  currencyCode: string;
  currencyId: number;
  currencyName: string;
  maxValue: number;
  minValue: number;
  type: string;
}

interface IBank {
  id: number;
  name: string;
}

export interface IPayMethods {
  id: number;
  sitePayInMethods: IPayCurrency[];
  sitePayOutMethods: IPayCurrency[];
  image: string;
  name: string;
  isNative? :boolean;
  link?: string;
  metadata?: IBank[];
}