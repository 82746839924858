import { auth } from 'constants/errors';
import AuthService from 'services/auth.service';
import { useStorage } from '../authHooks/useStorage';
import { generateHeaders } from 'utils/utils';
import { useAuthContext } from 'context/auth/authContext';

export const useApiErrors = () => {
  const { userRefresh, setUserTokens } = useStorage();
  const { setHasAccessToken } = useAuthContext();

  const errorHandler = async (status: number, message: string) => {
    const isExpiredToken = status === 403 && message === auth.AUTHORIZATION_TOKEN_EXPIRED;
    if (isExpiredToken) {
      setHasAccessToken(!isExpiredToken);
      try {
        const {
          data: { data, status },
        } = await AuthService.refreshToken({ refreshToken: userRefresh() });
        if (status === 200) {
          setUserTokens(data);
          const header = generateHeaders(data.accessToken);
          return { newHeader: header, needLogout: false };
        }
      } catch (err: any) {
        if (err?.response) {
          const {
            response: {
              data: { message },
            },
          } = err;
          return {
            newHeader: null,
            needLogout:
              message === auth.REQUEST_REFRESH_WRONG ||
              message === auth.REFRESH_TOKEN_WRONG ||
              message === auth.REFRESH_TOKEN_EXPIRED,
          };
        } else {
          return {
            newHeader: null,
            needLogout:
              err.message === auth.REQUEST_REFRESH_WRONG ||
              err.message === auth.REFRESH_TOKEN_WRONG ||
              err.message === auth.REFRESH_TOKEN_EXPIRED,
          };
        }
      }
    } else if (message === auth.REFRESH_TOKEN_WRONG) {
      return { newHeader: null, needLogout: true };
    }
    return null;
  };

  return { errorHandler };
};
