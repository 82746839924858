import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import cx from 'classnames';
import styles from './termCard.module.scss';

interface TermCardProps {
  title: string;
  description: string;
  img: string;
  index: number;
  isFaqs?: boolean;
}

export default function TermCard({ title, description, img, index }: TermCardProps) {
  return (
    <Box className={cx(styles.card, { [styles.isOdd]: !!(index % 2) })}>
      <Box className={styles.card_media}>
        <img width="200" src={img} alt="cardImage" />
      </Box>
      <Box className={styles.card_content}>
        <Typography gutterBottom component="h6" sx={{ textTransform: 'uppercase' }}>
          {title}
        </Typography>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: description ? description : '' }}
        ></div>
      </Box>
      <Box className={styles.card_actions}>{/* <Button size="small">Подробнее</Button> */}</Box>
    </Box>
  );
}
