import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  Link as LinkMail,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import logo from 'assets/logo/logo.webp';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import footerCircle from 'assets/icons/footerCircle.svg';
import LanguageMenu from 'components/languageMenu/languageMenu';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { FIELD_NAME } from 'types/login';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { ProvidersCarousel } from 'components/carousel/providersCarousel';
import { useProviders } from 'hooks/providersHooks/useProviders';
import { useAuthContext } from 'context/auth/authContext';
import { useModalContext } from 'context/modal/modalContext';
import { useSelector } from 'react-redux';
import { ISiteSocial } from 'types/site';
import { moke_data, moke_data_mobile, support_menu_mobile } from 'components/navBar/mocked_data';
import EmailIcon from 'assets/icons/email.svg';
import { links, mobileMenuItems } from 'constants/common';
import styles from './footer.module.scss';

const mailToObj: ISiteSocial = { id: 9, image: EmailIcon, name: 'email', url: links.MAIL };

function Footer() {
  const { t: menuT } = useTranslation('footer');
  const year = new Date().getFullYear();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const { openModalWithData } = useModalContext();
  const [openedIndex, setOpenedIndex] = useState('');
  const { geAlltProviders, getAllProvidesWithoutGames } = useProviders();
  const [showButton, setShowButton] = useState<boolean>(false);
  const providersWithoutGames = useSelector<any>((state) => state.providersWithoutGames) as any;
  const { socials } = useSelector<any>((state) => state.siteConfigs) as { socials: ISiteSocial[] };
  const [open, setOpen] = useState(false);
  const supportList = [...support_menu_mobile];
  supportList.splice(-1);

  const handleOnClickSupport = () => {
    setOpen(!open);
  };

  const handleOnClick = (event: React.MouseEvent<HTMLElement>, name: string, link: string) => {
    setOpenedIndex('');
    if (name === 'payment') {
      event.preventDefault();
      if (user) {
        navigate(link);
      } else {
        openModalWithData(FIELD_NAME.LOGIN, {
          redirect: link,
        });
      }
    } else if (name === 'onlineChat' || name === 'TGSupport') {
      event.preventDefault();
      window.open(link, '_blank');
    }
  };

  const handleClick = (id: string) => {
    if (id === openedIndex) {
      setOpenedIndex('');
    } else {
      setOpenedIndex(id);
    }
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const getData = async () => {
    await geAlltProviders();
    await getAllProvidesWithoutGames();
  };

  useEffect(() => {
    getData();

    const handleScrollButtonVisiblity = () => {
      window.pageYOffset > 400 ? setShowButton(true) : setShowButton(false);
    };

    window.addEventListener('scroll', handleScrollButtonVisiblity);

    return () => {
      window.removeEventListener('scroll', handleScrollButtonVisiblity);
    };
  }, []);

  return (
    <Box className={styles.general_area}>
      {showButton && (
        <IconButton className={styles.scroll_to_up} onClick={handleScrollToTop}>
          {<ExpandLessIcon fontSize="large" />}
        </IconButton>
      )}
      <Box className={styles.content}>
        <Box className={styles.community_area}>
          <Box className={styles.logo_area}>
            <img src={logo} className={styles.logo} alt="logo" width={120} />
          </Box>
          <Box className={styles.community_info}>
            {socials?.map((social: ISiteSocial) => {
              return (
                <Box key={social.id} sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  <img
                    className={styles.community_icon}
                    src={social.image}
                    alt="communityIcon"
                    width={28}
                  />
                  <Link
                    to={social.url}
                    rel="noopener"
                    target="_blank"
                    className={styles.links_title}
                  >
                    {menuT(social.name)}
                  </Link>
                </Box>
              );
            })}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <img
                className={styles.community_icon}
                src={mailToObj.image}
                alt="emailIcon"
                width={28}
              />
              <LinkMail href={`mailto:${mailToObj.url}`} className={styles.links_title}>
                {menuT(mailToObj.name)}
              </LinkMail>
            </Box>
          </Box>
        </Box>
        <Box className={styles.mobile_version}>
          {moke_data_mobile.map(({ id, menu, subMenu }, index) => {
            return (
              <Box key={index} className={styles.main_span}>
                <ListItemButton key={`sub${index}`} onClick={() => handleClick(id)}>
                  <ListItemText
                    primary={menuT(menu)}
                    sx={{
                      span: {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  />
                  {openedIndex === id ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                {subMenu.map(({ menu, link }, index) => {
                  return (
                    <Collapse
                      key={`subItem${index}`}
                      in={openedIndex === id}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div">
                        <Link to={link} color={'inherit'} title={menuT(menu)}>
                          <ListItemButton sx={{ pl: 4 }}>
                            <ListItemText
                              primary={menuT(menu)}
                              sx={{
                                span: {
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                },
                              }}
                              onClick={(e) => handleOnClick(e, menu, link)}
                            />
                          </ListItemButton>
                        </Link>
                      </List>
                    </Collapse>
                  );
                })}
              </Box>
            );
          })}
        </Box>
        {moke_data.map(({ menu, subMenu }, i) => {
          return (
            <Box className={styles.links_div} key={i}>
              <Typography className={styles.links_title}>{menuT(menu)}</Typography>
              {subMenu.map(({ menu, link }, i) => {
                if (menu === mobileMenuItems.SUPPORT) {
                  return (
                    <List key={i} sx={{ width: '100%', maxWidth: 360 }} component="nav">
                      <Link to={''} onClick={handleOnClickSupport}>
                        {menuT(menu)}
                      </Link>
                      {supportList.map((el, index) => {
                        return (
                          <Collapse key={index} in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                              <Link
                                to={el.link}
                                target="_blank"
                                onClick={(e) => handleOnClick(e, menu, link)}
                              >
                                {menuT(el.menu)}
                              </Link>
                            </List>
                          </Collapse>
                        );
                      })}
                    </List>
                  );
                }
                return (
                  <Typography className={styles.links} key={i}>
                    <Link to={link} onClick={(e) => handleOnClick(e, menu, link)}>
                      {menuT(menu)}
                    </Link>
                  </Typography>
                );
              })}
            </Box>
          );
        })}
        <Box className={styles.links_div}>
          <Typography className={styles.links_title}>{menuT('languages')}</Typography>
          <LanguageMenu isMobile />
        </Box>
      </Box>

      <Box sx={{ width: '90%', margin: 'auto' }}>
        <ProvidersCarousel carouselItems={providersWithoutGames} withoutGames />
      </Box>

      <Box className={styles.site_info}>
        <img src={footerCircle} alt="info" width="13" />
        <Typography className={styles.text}>
          2023-{year} {menuT('companyName')}
        </Typography>
      </Box>
    </Box>
  );
}

export default Footer;
