import Backdrop from '@mui/material/Backdrop';
import logoMobile from 'assets/logo/loader.gif';

interface ILoader {
  isOpen: boolean;
}

export default function LoaderWithLogo({ isOpen }: ILoader) {
  return (
    <div>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          span: { width: '90px!important', height: '90px!important', opacity: '0.5' },
        }}
        open={isOpen}
      >
        <img src={logoMobile} style={{ position: 'absolute', maxWidth: '500px' }} alt="logo" />
      </Backdrop>
    </div>
  );
}
