import { useStorage } from 'hooks/authHooks/useStorage';
import { useApiErrors } from 'hooks/errors/useApiErrors';
import { useUser } from 'hooks/user/useUser';
import { useState } from 'react';
import GameService from 'services/game.service';
import ProviderService from 'services/provider.service';
import { generateHeaders } from 'utils/utils';

export const useSubProviderGames = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState('');
  const [totalItems, setTotalItems] = useState();
  const [totalPages, setTotalPages] = useState();
  const { userToken } = useStorage();
  const { removeUser } = useUser();
  const { errorHandler } = useApiErrors();
  const initHeaders = generateHeaders(userToken());

  const getAllSubProviderGames: any = async (
    query: string,
    forPublic?: string,
    headers = initHeaders,
  ) => {
    try {
      setIsLoading(true);
      const {
        data: {
          data: { games, totalItems, totalPages },
          status,
        },
      } = await GameService.getAllGameListByQuery(headers, forPublic, query);
      setTotalPages(totalPages);
      setTotalItems(totalItems);
      if (status === 200) return { status, data: { games, totalPages } };
    } catch (error: any) {
      if (error.response) {
        const {
          response: {
            data: { message, status },
          },
        } = error;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await getAllSubProviderGames(query, forPublic, newHeader);
        } else {
          console.log('LOG____>', message);
          setError(message);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getAllSubProvidersByCategoryId: any = async (
    categoryId: string,
    query: string,
    headers = initHeaders,
  ) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await ProviderService.getAllSubProvidersByCategoryId(headers, categoryId, query);
      setIsLoading(false);
      return { status, data };
    } catch (error: any) {
      if (error.response) {
        const {
          response: {
            data: { message, status },
          },
        } = error;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await getAllSubProvidersByCategoryId(categoryId, query, newHeader);
        } else {
          console.log('LOG____>', message);
          setError(message);
        }
      }
    }
  };

  return {
    getAllSubProviderGames,
    getAllSubProvidersByCategoryId,
    isLoading,
    error,
    totalItems,
    totalPages,
  };
};
