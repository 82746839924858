import {
  AlertColor,
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { TabContext, TabPanel } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { renderItem } from 'components/deposit/deposit';
import {
  isValidCardNumber,
  isNumber,
  isValidPhonePattern,
  isStartingWith,
} from 'utils/validations/validationTypes';
import { useTransaction } from 'hooks/transactions/useTransaction';
import { useDispatch, useSelector } from 'react-redux';
import Result from 'components/alert/alert';
import { usePayment } from 'hooks/payment/usePayment';
import { FIELD_NAME, IPayMethods } from 'types/payment';
import { RESULT } from 'types/common';
import LoaderWithBg from 'components/loader/loader';
import common from 'styles/colors.module.scss';
import InputMask from 'react-input-mask';
import cx from 'classnames';
import Dialogs from 'components/dialog/dialogs';
import { useUserData } from 'hooks/user/useUserData';
import { useStorage } from 'hooks/authHooks/useStorage';
import { updateUserBalance } from 'redux/actions/userDataAction';
import styles from '../deposit/deposit.module.scss';

const fields = {
  [FIELD_NAME.MIN]: true,
  [FIELD_NAME.CARD]: true,
  [FIELD_NAME.PHONE]: true,
  [FIELD_NAME.BANK_LIST]: true,
};

const validationsById = {
  6: 17,
  7: 12,
} as { [x: string]: number };

function Withdraw() {
  const { t } = useTranslation('deposit');
  const dispatch = useDispatch();
  const { isLoading: isLoadingWithdraw, payOut, error, resetError } = usePayment();
  const { t: commonT } = useTranslation('common');
  const { t: paymentT } = useTranslation('payment');
  const { t: validationT } = useTranslation('validation');
  const { updateData } = useStorage();
  const [value] = useState('0');
  const [activePayment, setAtivePayment] = useState<IPayMethods>();
  const [quantity, setQuantity] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const userData = useSelector<any>((state) => state.userData) as any;
  const [validFields, setValidFields] = useState({ ...fields });
  const [requestMessage, setRequestMessage] = useState<string>('');
  const [type, setType] = useState<AlertColor>('success');
  const { isLoading, methodsList, getAllPayOutMethods } = useTransaction();
  const [errorName, setErrorName] = useState('');
  const [bankName, setBankName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const { trasferUserBalance } = useUserData();
  const elementRef = useRef<HTMLDivElement>(null);
  const walletAmount = userData?.balance.filter((el: any) => el.id === 3)?.[0]?.amount;
  const gameAmount = userData?.balance.filter((el: any) => el.id === 2)?.[0]?.amount;

  const isValidWallet = (num: string, id?: number) => {
    if (id === 6) {
      return (
        isStartingWith(num, 'F') &&
        num.length === validationsById[String(id)] &&
        isNumber((num + '').substring(1))
      );
    }
    return num.length === validationsById[String(id)] && isNumber(num);
  };

  const renderContent = (type: string, methodId?: number) => {
    switch (type) {
      case 'SBP':
        return (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <InputMask
                mask="+7(999)-999-99-99"
                value={phoneNumber}
                onChange={handleOnChangePhone}
              >
                {() => (
                  <TextField
                    size="small"
                    label={commonT('phoneNumber')}
                    variant="outlined"
                    fullWidth
                    value={phoneNumber}
                    onChange={handleOnChangePhone}
                    placeholder="+7(918)-000-00-00"
                    error={!validFields[FIELD_NAME.PHONE]}
                    helperText={
                      !validFields[FIELD_NAME.PHONE] ? validationT('invalidPhoneNumber') : ''
                    }
                    sx={{
                      '& .MuiFormHelperText-root': {
                        marginTop: 0,
                        width: '100%',
                      },
                    }}
                  />
                )}
              </InputMask>
            </Box>
            <FormControl fullWidth size="small" className={styles.select}>
              <InputLabel id="bankList-name-label">{commonT('bankList')}</InputLabel>
              <Select
                labelId="bankList-name-label"
                id="bankList-select"
                value={bankName}
                label={commonT('bankList')}
                onChange={handleChangeBank}
                error={!validFields[FIELD_NAME.BANK_LIST]}
                required
              >
                {activePayment?.metadata?.map((el) => {
                  return (
                    <MenuItem value={el.id} key={el.id}>
                      {el.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText sx={{ color: common.mainOrange, mt: 0 }}>
                {!validFields[FIELD_NAME.BANK_LIST] ? validationT('slectName') : ''}
              </FormHelperText>
            </FormControl>
          </>
        );
      case 'CARD':
        return (
          <InputMask mask="9999-9999-9999-9999" value={cardNumber} onChange={handleOnChangeCard}>
            {() => (
              <TextField
                size="small"
                label={commonT('cardNum')}
                variant="outlined"
                fullWidth
                value={cardNumber}
                onChange={handleOnChangeCard}
                placeholder="9999-9999-9999-9999"
                error={!validFields[FIELD_NAME.CARD]}
                helperText={
                  !validFields[FIELD_NAME.CARD]
                    ? validationT('minLengthofCard', { length: 16 })
                    : ''
                }
                sx={{
                  '& .MuiFormHelperText-root': {
                    marginTop: 0,
                  },
                }}
              />
            )}
          </InputMask>
        );

      case 'WALLET':
        if (methodId === 6)
          return (
            <InputMask mask="F9999999999999999" value={cardNumber} onChange={handleOnChangeCard}>
              {() => (
                <TextField
                  size="small"
                  label={commonT('walletNum')}
                  variant="outlined"
                  fullWidth
                  value={cardNumber}
                  onChange={handleOnChangeCard}
                  placeholder="F9999999999999999"
                  error={!validFields[FIELD_NAME.CARD]}
                  helperText={!validFields[FIELD_NAME.CARD] ? validationT('notValid') : ''}
                  sx={{
                    '& .MuiFormHelperText-root': {
                      marginTop: 0,
                    },
                  }}
                />
              )}
            </InputMask>
          );
        return (
          <InputMask mask="999999999999" value={cardNumber} onChange={handleOnChangeCard}>
            {() => (
              <TextField
                size="small"
                label={commonT('walletNum')}
                variant="outlined"
                fullWidth
                value={cardNumber}
                onChange={handleOnChangeCard}
                placeholder="999999999999"
                error={!validFields[FIELD_NAME.CARD]}
                helperText={!validFields[FIELD_NAME.CARD] ? validationT('notValid') : ''}
                sx={{
                  '& .MuiFormHelperText-root': {
                    marginTop: 0,
                  },
                }}
              />
            )}
          </InputMask>
        );
      default:
        break;
    }
  };

  const resetStates = () => {
    setQuantity('');
    setErrorName('');
    setCardNumber('');
    setPhoneNumber('');
    setBankName('');
    resetValidFields(FIELD_NAME.MIN);
  };

  const resetValidFields = (name: string) => {
    setValidFields((fields) => ({
      ...fields,
      [name]: true,
    }));
    if (name === FIELD_NAME.MIN) setErrorName('');
  };

  const handleOnClose = () => {
    resetError();
    setRequestMessage('');
    if (activePayment?.id === 3) {
      setBankName('');
      setPhoneNumber('');
    }
  };

  const checkValidation = (activePayment: IPayMethods, name: string, value: string) => {
    let isValid = true;

    switch (name) {
      case FIELD_NAME.CARD:
        isValid = value.length === 19;
        break;
      case FIELD_NAME.MIN:
        if (+value > activePayment?.sitePayOutMethods?.[0]?.maxValue) {
          setErrorName('isBiggerMaxVal');
        } else if (+value < activePayment?.sitePayOutMethods?.[0]?.minValue) {
          setErrorName('isSmallerMinVal');
        } else if (+value > walletAmount) {
          setErrorName('notEnoughMoney');
        }
        isValid =
          +value >= activePayment?.sitePayOutMethods?.[0]?.minValue &&
          +value <= activePayment?.sitePayOutMethods[0]?.maxValue &&
          +value <= walletAmount;
        break;
      default:
    }
    return isValid;
  };

  const handleOnBlur = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: string,
  ) => {
    const { value } = e.target;
    if (activePayment && value != '') {
      if (name === FIELD_NAME.CARD) {
        const num = value.split('');
        for (let i = 4; i < num.length - 1; i += 5) {
          if (num[i] !== '-') {
            num.splice(i, 0, '-');
          }
        }
        if (checkValidation(activePayment, name, num.join(''))) {
          setCardNumber(num.join(''));
        } else {
          setValidFields((fields) => ({
            ...fields,
            [FIELD_NAME.CARD]: false,
          }));
        }
      } else {
        validate(value, name);
      }
    }
  };

  const validate = (value: any, name: string) => {
    if (activePayment) {
      setValidFields((fields) => ({
        ...fields,
        [name]: checkValidation(activePayment, name, value),
      }));
    }
  };

  const makeRequest = async (body: any) => {
    const res = await payOut(activePayment?.id, body);
    if (res?.status === 200) {
      setRequestMessage(paymentT(RESULT.PENDING_W));
      setType(RESULT.SUCCESS);
      resetStates();
    }
  };

  const handleOnClick = () => {
    const isValid =
      activePayment?.id !== 3
        ? activePayment?.id !== 6 && activePayment?.id !== 7
          ? cardNumber !== '' && isValidCardNumber(cardNumber)
          : cardNumber !== '' && isValidWallet(cardNumber, activePayment?.id)
        : phoneNumber.length === 17 && isValidPhonePattern(phoneNumber) && bankName != '';

    if (
      activePayment &&
      +quantity !== 0 &&
      isValid &&
      +quantity <= walletAmount &&
      validFields[FIELD_NAME.MIN]
    ) {
      let body: any = {
        amount: +quantity,
        requisites: activePayment?.id !== 3 ? cardNumber.split('-').join('') : phoneNumber,
        comment: null,
      };
      if (activePayment?.id === 3) {
        body = {
          ...body,
          bankName: activePayment?.metadata?.filter((el) => el.id === +bankName)?.[0]?.name ?? '',
        };
      }
      makeRequest(body);
    } else {
      if (
        isValid &&
        +quantity > walletAmount &&
        gameAmount >= +quantity &&
        activePayment &&
        +quantity <= activePayment?.sitePayOutMethods?.[0]?.maxValue
      ) {
        setIsOpen(true);
      }
      const validFields =
        activePayment?.id !== 3
          ? activePayment?.id !== 6 && activePayment?.id !== 7
            ? {
                [FIELD_NAME.CARD]: cardNumber !== '' && isValidCardNumber(cardNumber),
              }
            : {
                [FIELD_NAME.CARD]:
                  cardNumber !== '' && isValidWallet(cardNumber, activePayment?.id),
              }
          : {
              [FIELD_NAME.PHONE]: phoneNumber.length === 17 && isValidPhonePattern(phoneNumber),
              [FIELD_NAME.BANK_LIST]: bankName !== '',
            };

      setValidFields((fields) => ({
        ...fields,
        ...validFields,
        [FIELD_NAME.MIN]: activePayment
          ? checkValidation(activePayment, FIELD_NAME.MIN, quantity)
          : false,
      }));
    }
  };

  const handleConfirm = async () => {
    const walletBalance = userData?.balance?.filter(
      (el: { type: FIELD_NAME }) => el.type === 'wallet',
    );
    const gameBalance = userData?.balance?.filter((el: { type: FIELD_NAME }) => el.type === 'game');

    const body = { amount: gameAmount, from: gameBalance[0].id, to: walletBalance[0].id };
    const result = await trasferUserBalance(body);
    if (result?.status === 200) {
      updateData({ balance: result?.data });
      dispatch(updateUserBalance(result.data));

      const body = {
        amount: +quantity,
        requisites: activePayment?.id !== 3 ? cardNumber.split('-').join('') : phoneNumber,
        bankName: activePayment?.metadata?.filter((el) => el.id === +bankName)?.[0]?.name ?? '',
        comment: null,
      };

      makeRequest(body);
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    if (isNumber(value)) {
      setQuantity(value);
    }
    resetValidFields(FIELD_NAME.MIN);
    setRequestMessage('');
  };

  const handleChangeBank = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setBankName(value);
    resetValidFields(FIELD_NAME.BANK_LIST);
  };

  const handleOnChangeCard = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    setCardNumber(value);
    resetValidFields(FIELD_NAME.CARD);
  };

  const handleOnChangePhone = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    setPhoneNumber(value);
    resetValidFields(FIELD_NAME.PHONE);
  };

  const changeActivePayment = (item: IPayMethods) => {
    setAtivePayment(item);
    resetStates();
    resetError();
    setRequestMessage('');
    setValidFields({ ...fields });
    if (elementRef.current) {
      elementRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  useEffect(() => {
    const isCripto = !!+value;
    getAllPayOutMethods(`isCrypto=${isCripto}`);
  }, [value]);

  useEffect(() => {
    if (methodsList.length) {
      setAtivePayment(methodsList[0]);
    }
  }, [methodsList]);

  return (
    <Box className={styles.main_area}>
      {isLoadingWithdraw ? <LoaderWithBg isOpen={isLoadingWithdraw} /> : null}
      <Box className={styles.main_title}>{t('withdraw')}</Box>
      <Box className={styles.methods_block}>
        <Box className={styles.header}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box className={styles.title}>{paymentT('titleWithdraw')}</Box>
            <Box className={styles.bullet} />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box className={styles.current_amount}>
              <Typography>{t('currAmount')}:</Typography>
              <Typography sx={{ color: common.mainOrange, ml: 2, fontWeight: 800 }}>
                {walletAmount} {userData?.currency?.code}
              </Typography>
            </Box>
            <Box className={styles.current_amount}>
              <Typography>{t('gameBalance')}:</Typography>
              <Typography sx={{ color: common.mainOrange, ml: 2, fontWeight: 800 }}>
                {gameAmount} {userData?.currency?.code}
              </Typography>
            </Box>
          </Box>
        </Box>
        <TabContext value={value}>
          <TabPanel value="0" className={styles.own_tab}>
            <Box className={styles.deposit_block}>
              {isLoading ? (
                <Box className={styles.loading}>{commonT('loading')}</Box>
              ) : (
                methodsList.map((item: IPayMethods, index: number) => {
                  return renderItem(
                    activePayment?.id === item.id,
                    item,
                    t('min'),
                    index,
                    item.sitePayOutMethods[0].minValue,
                    item.sitePayOutMethods[0].currencyCode,
                    changeActivePayment,
                  );
                })
              )}
            </Box>
          </TabPanel>
        </TabContext>
        <Box className={styles.promo} ref={elementRef}>
          <TextField
            label={t('count', {
              val: activePayment?.sitePayOutMethods[0].minValue,
              curr: `${userData?.currency?.code}`,
            })}
            size="small"
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={quantity}
            onChange={handleOnChange}
            onBlur={(e) => handleOnBlur(e, FIELD_NAME.MIN)}
            error={!validFields[FIELD_NAME.MIN]}
          />
          <Box className={styles.currency_name}>{userData?.currency?.code} </Box>
        </Box>
        {!validFields[FIELD_NAME.MIN] && errorName === 'isSmallerMinVal' ? (
          <Typography className={styles.error}>
            {validationT('minMustBe', { length: activePayment?.sitePayOutMethods[0].minValue })}{' '}
            {commonT('forWithdraw')}
          </Typography>
        ) : !validFields[FIELD_NAME.MIN] && errorName === 'isBiggerMaxVal' ? (
          <Typography className={styles.error}>
            {validationT('maxMustBe', { length: activePayment?.sitePayOutMethods[0].maxValue })}{' '}
            {commonT('forWithdraw')}
          </Typography>
        ) : !validFields[FIELD_NAME.MIN] && errorName === 'notEnoughMoney' ? (
          <Typography className={styles.error}>{commonT('notEnoughMoney')}</Typography>
        ) : null}
      </Box>

      <Box className={styles.promo_block}>
        <Box
          className={cx(styles.promo, styles.withdraw, {
            [styles.is_SBP]: activePayment?.id === 3,
          })}
        >
          {renderContent(activePayment?.sitePayOutMethods[0]?.type ?? '', activePayment?.id)}
        </Box>
      </Box>
      <Result
        style={{ mt: 2 }}
        message={requestMessage || commonT(error)}
        type={error ? RESULT.ERROR : type}
        isShow={!!(requestMessage || error)}
        hasAction
        onClose={handleOnClose}
      />
      <Box sx={{ mb: 3 }}>
        <Button
          sx={{ mt: 2, backgroundColor: common.mainOrange }}
          fullWidth
          variant="contained"
          onClick={handleOnClick}
          disabled={
            !validFields[FIELD_NAME.CARD] ||
            !validFields[FIELD_NAME.PHONE] ||
            !validFields[FIELD_NAME.BANK_LIST] ||
            isLoadingWithdraw
          }
        >
          {commonT('sendRequest')}
        </Button>
      </Box>

      <Dialogs
        title={''}
        onConfirm={handleConfirm}
        content={paymentT('notEnoughMoney')}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        btnText={commonT('transfer')}
        ignoreBtnText={commonT('cancel')}
        contentStyle={{ padding: '0 12px' }}
      />
    </Box>
  );
}

export default Withdraw;
