import { useApiErrors } from 'hooks/errors/useApiErrors';
import { useStorage } from 'hooks/authHooks/useStorage';
import { useState } from 'react';
import { generateHeaders } from 'utils/utils';
import { useUser } from 'hooks/user/useUser';
import UserService from 'services/user.service';
import { useAuthContext } from 'context/auth/authContext';

export const useBalance = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { removeUser } = useUser();
  const [error, setError] = useState('');
  const { userToken } = useStorage();
  const { errorHandler } = useApiErrors();
  const { hasAccessToken, setHasAccessToken } = useAuthContext();
  const initHeaders = generateHeaders(userToken());

  const getUserBalance: any = async (headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await UserService.getUserBalance(headers);

      if (status === 200) {
        return { data };
      }
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { message, status },
          },
        } = err;
        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await getUserBalance(newHeader);
        } else {
          console.log('LOG____> playGame', message);
          setError(message);
        }
      }
    } finally {
      setIsLoading(false);
      if (!hasAccessToken) setHasAccessToken(true);
    }
  };

  const getUserBalanceChangesAll: any = async (query: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: {
          data: { siteBalanceChanges, totalPages },
          status,
        },
      } = await UserService.getUserBalanceChanges(headers, query);

      if (status === 200) {
        return { data: siteBalanceChanges, totalPages };
      }
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { message, status },
          },
        } = err;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await getUserBalanceChangesAll(query, newHeader);
        } else {
          console.log('LOG____> getUserBalanceChangesAll', message);
          setError(message);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };
  return {
    isLoading,
    getUserBalance,
    getUserBalanceChangesAll,
    error,
    resetError: () => setError(''),
  };
};
