import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import _color from 'styles/colors.module.scss';

interface ILoader {
  isOpen: boolean;
}

export default function LoaderWithBg({ isOpen }: ILoader) {
  return (
    <div>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isOpen}>
        <CircularProgress sx={{ color: _color.mainOrange }} />
      </Backdrop>
    </div>
  );
}
