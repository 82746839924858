import Card from '@mui/material/Card';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import IconButton from '@mui/material/IconButton';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import cx from 'classnames';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Box } from '@mui/material';
import { AsyncImage } from 'loadable-image';
import { Blur } from 'transitions-kit';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routing } from 'constants/routing';
import { useAuthContext } from 'context/auth/authContext';
import { useModalContext } from 'context/modal/modalContext';
import { FIELD_NAME } from 'types/login';
import noImage from 'assets/games/noImage.webp';
import { useTranslation } from 'react-i18next';
import { useGame } from 'hooks/playGames/useGame';
import { useSelector } from 'react-redux';
import { MODAL_TYPES } from 'types/modals';
import { IProviderGame } from 'types/game';
import styles from './gameCard.module.scss';

interface GameCardProps {
  imageUrl: string;
  gameId: string;
  providerId: string;
  withoutAction?: boolean;
  smallImages?: IProviderGame[];
  logo?: string;
  className?: string;
  gameName?: string;
  isFavorite?: boolean;
  isUnliked?: boolean;
}

export default function GameCard({
  imageUrl,
  isUnliked,
  gameName,
  gameId,
  providerId,
  withoutAction,
  smallImages,
  logo,
  className,
  isFavorite,
}: GameCardProps) {
  const { t } = useTranslation('common');
  const { device } = useSelector<any>((state) => state.device) as {
    device: string;
  };
  const { user } = useAuthContext();
  const { openModalWithData } = useModalContext();
  const { changeGameFavoriteStatus, changeGameUnlikedStatus } = useGame();
  const [isLiked, setIsLiked] = useState<boolean | undefined>(false);
  const navigate = useNavigate();

  const handleLikeClick = () => {
    if (user) {
      updateFavoriteStatus(!isLiked);
    } else {
      openModalWithData(FIELD_NAME.LOGIN_FOR_FAVORITE, {
        isFavorite: !isLiked,
        providerId,
        gameId,
        device,
      });
    }
  };

  const updateFavoriteStatus = async (like: boolean) => {
    const body = { isFavorite: like };
    const res = await changeGameFavoriteStatus(providerId, gameId, body);
    if (res?.status === 200) {
      setIsLiked(like);
      like && isUnliked ? await changeGameUnlikedStatus(gameId, false) : null;
    }
  };

  const handlePlayGame = (id?: string) => {
    if (user) {
      openModalWithData(MODAL_TYPES.CHOOSE_BALANCE, {
        gameId: id ? id : gameId,
        providerId: providerId,
        image: imageUrl,
        name: gameName,
        isLiked: isLiked,
        isUnliked: isUnliked,
      });
    } else {
      openModalWithData(FIELD_NAME.LOGIN_FOR_PLAY_GAME, {
        gameId: id ? id : gameId,
        providerId: providerId,
        image: imageUrl,
        name: gameName,
      });
    }
  };

  const handleDemoGame = () => {
    navigate(`${routing.gameDemo}`, {
      state: {
        gameId: gameId,
        providerId: providerId,
        image: imageUrl,
        name: gameName,
      },
    });
  };

  useEffect(() => {
    if (isLiked !== isFavorite) setIsLiked(isFavorite);
  }, [isFavorite]);

  return (
    <Box className={cx(styles.box_card, className)}>
      <Card
        className={cx(styles.card, {
          [styles.isProvider]: !!smallImages?.length,
        })}
      >
        {!withoutAction ? (
          <>
            {/*TODO: remove the comment or uncomment it after finalizing design */}
            {/* <Box className={styles.reversed}>{t('new')}</Box> */}
            <AsyncImage
              className={styles.special_height}
              src={imageUrl}
              alt="game"
              onError={({ currentTarget }: any) => (currentTarget.src = noImage)}
              loader={
                <div className={styles.special_height} style={{ background: 'trasparent' }} />
              }
              Transition={Blur}
            />
          </>
        ) : (
          <Box className={styles.card_with_small_games}>
            <Box className={styles.logo}>
              <img width="auto" src={logo} alt="providerLogo" height="30px" />
            </Box>
            <Box className={styles.small_games}>
              {smallImages?.map((item, index) => {
                return (
                  <Box key={index} onClick={() => handlePlayGame(item.id)}>
                    <img
                      width={60}
                      src={item.image}
                      onError={({ currentTarget }: any) => (currentTarget.src = noImage)}
                      alt="game"
                    />
                  </Box>
                );
              })}
            </Box>
            <Box className={styles.img}>
              <img src={imageUrl} alt="game" width="auto" />
            </Box>
          </Box>
        )}
      </Card>
      {!withoutAction ? (
        <Box className={styles.card_content}>
          <Box className={styles.action}>
            <IconButton className={styles.play} onClick={() => handlePlayGame()}>
              <PlayCircleIcon />
            </IconButton>
            {/* <Button className={styles.demo} onClick={handleDemoGame}>
              {t('demo')}
            </Button> */}
          </Box>
          <Box className={styles.heart}>
            <IconButton
              aria-label="add to favorites"
              sx={{ color: '#fff' }}
              onClick={handleLikeClick}
            >
              {isLiked ? (
                <FavoriteIcon sx={{ width: '20px' }} />
              ) : (
                <FavoriteBorderIcon sx={{ width: '20px' }} />
              )}
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Box className={styles.card_content_with_small}> </Box>
      )}
    </Box>
  );
}
