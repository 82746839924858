import Cash from 'assets/bonuses/cash.webp';
import Mail from 'assets/bonuses/mail.webp';
import Promo from 'assets/bonuses/promo.webp';
import Vk from 'assets/bonuses/vk.webp';
import Telegram from 'assets/bonuses/telegram.webp';
import Facebook from 'assets/bonuses/fb.webp';
import { links } from 'constants/common';

export const mocked_Bonuses = {
  ru: [
    {
      id: '1',
      title: 'Подпишись на наш телеграмм',
      description: {
        title: '',
        content: `Для Вашего удобства мы создали Телеграмм <a href = "${links.TG_CHANNEL}"  target="_blank"> канал </a>,
                где Вас ждёт информация о бонусах, акциях и актуальных ссылках на сайт . Подпишитесь на наш Телеграмм канал, участвуйте в розыгрышах и узнайте новости раньше всех.`,
      },
      icon: Telegram,
    },
    {
      id: '2',
      title: 'Вконтакт',
      description: {
        title: '',
        content: `Вступайте в нашу группу в социальной сети Вконтакте magnitxcasino вы получите бонус на игровой баланс. В группе так же разыгрывают индивидуальные «промо коды». Размер бонуса зависит от уровня клиента.`,
      },
      links: { name: 'vk', link: links.VK_CHANNEL, buttonText: 'VK Канал' },
      icon: Vk,
    },
    {
      id: '3',
      title: 'Бонус доступен только при подтвержденной почте',
      description: {
        title: '',
        content: `После успешного подтверждения почты игрок получает бонус. Бонус необходимо отыграть, то есть сделать общую сумму ставок согласно условиям бонуса (вейджер). 
        Бонус доступен тем игрокам которые активно играли в течение месяца.`,
      },
      links: { name: 'email', link: links.MAIL, buttonText: 'Написать' },
      icon: Mail,
    },
    {
      id: '4',
      title: 'Промокод для новых клиентов',
      description: {
        title: '',
        content: `Бездепозитный бонус в казино Магнит-Х  за регистрацию можно получить только по нашему промокоду, который выдается только по приглашению.
         Долгое отсутствие на сайте казино может привести к пропуску периода действия промокода. Промокод можно активировать при регистрации, либо потом в разделе «Депозит».`,
      },
      icon: Promo,
    },
    {
      id: '5',
      title: 'КЭШБЭК',
      description: {
        title: '',
        content: `Каждый день в 09:00 по МСК казино Магнит-Х автоматически начисляет кэшбэк — возврат части проигранных средств от суммы всех депозитов, совершённых в течение предыдущего дня. Процент кэшбэка зависит от уровня клиента и варьируется от 2 % до 8 %. Игрок имеет право снять кэшбэк без отыгрыша.`,
      },
      icon: Cash,
    },
    {
      id: '6',
      title: 'УРОВЕНЬ',
      description: {
        title: '',
        content: `Играйте активно для того чтобы повысить свой уровень. Уровни включают в себя индивидуальные  бонусы, эксклюзивные предложения, 
        повышенные лимиты и персонализированные условия. Каждый уровень предоставляет игрокам уникальные привилегии, и чем выше уровень, тем более персонализированные и ценные бонусы и предложения.`,
      },
      icon: Facebook,
      links: { name: 'levels', link: '', buttonText: 'Перейти' },
    },
  ],
  en: [
    {
      id: '1',
      title: 'Subscribe to our telegram',
      description: {
        title: '',
        content: `For your convenience, we have created a Telegram channel ${links.TG_CHANNEL}, 
        where you will find information about Bonuses, promotions and current links to the website.
        Subscribe to our Telegram channel and read the news before anyone else.`,
      },
      icon: Telegram,
    },
    {
      id: '2',
      title: 'Vkontakt',
      description: {
        title: '',
        content: `Join our group on the social network “VKontakte” receive 100 rubles in 
        your game balance. Individual “promo codes” are being drawn in the group
        THE BONUS IS AVAILABLE TO THOSE PLAYERS WHO ACTIVELY PLAYED DURING THE MONTH`,
      },
      icon: Vk,
      links: { name: 'vk', link: links.VK_CHANNEL, buttonText: 'VK Channel' },
    },
    {
      id: '3',
      title: 'BONUS AVAILABLE ONLY WITH CONFIRMED EMAIL',
      description: {
        title: '',
        content: `The bonus must be wagered, that is, the total amount of bets must be made according to the terms of the bonus.
        No winnings will be paid out until bonuses are fully wagered. 
        Money won by using bonuses cannot be withdrawn from the account until all bonuses have been wagered.
       Received bonuses are wagered in order of priority.
       THE BONUS IS AVAILABLE TO THOSE PLAYERS WHO ACTIVELY PLAYED DURING THE MONTH`,
      },
      icon: Mail,
      links: { name: 'email', link: links.MAIL, buttonText: 'Write' },
    },
    {
      id: '4',
      title: 'PROMO CODE FOR NEW CLIENTS',
      description: {
        title: '',
        content: `A no deposit bonus at the casino Magnit - X for registration can only be received using our promo code, which is issued only by INVITATION.
        All offered promo codes from casino Magnit - X are aimed at ensuring that the player spends as much time as possible on the site.
        A long absence from the casino website can lead to missing the period of validity of the promotional code and to the loss of the bonus activated by it.
        
        The promotional code can be activated immediately upon registration, or later in the “Deposit” section.`,
      },
      icon: Promo,
    },
    {
      id: '5',
      title: 'CASHBACK',
      description: {
        title: '',
        content: `Every day at 09:00 (Moscow time), the Casino website credits Cashback in 
        the amount of 5% of the amount of all your deposits made during the previous day.
        Additional cashback from some providers is 3% - 5% depending on the client level.
        5% cashback return is received by all players whose activity on the website was within 24 hours.
        In case of loss of bonus funds, cashback is also awarded.
        The player has the right to withdraw cashback without deducting a commission.
        Cashback is accrued automatically depending on the client’s level.`,
      },
      icon: Cash,
    },
    {
      id: '6',
      title: 'LEVEL',
      description: {
        title: '',
        content: `Get 100 rubles every month!
          The bonus is available for those players who have Bronze status and higher!
          Text a message to manager on FaceBook and click 'Add to Friends' button. 
          The manager will confirm the request and top up your gaming account with 100 rubles every month!
          This type of bonus is not available for the Basic level!
          THE BONUS IS AVAILABLE TO THOSE PLAYERS WHO ACTIVELY PLAYED DURING THE MONTH.`,
      },
      links: { name: 'levels', link: '', buttonText: 'Go to' },
      icon: Facebook,
    },
  ],
} as { [x: string]: any };
