import Button from '@mui/material/Button';
import cx from 'classnames';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useState } from 'react';
import { generateHeaders, hasEmptyProps, isEmpty } from 'utils/utils';
import common from 'styles/colors.module.scss';
import Result from 'components/alert/alert';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputBase,
  InputLabel,
  Link,
  styled,
} from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { isValidValue } from 'utils/helpers/inputHelper';
import { IFormData, IErrors, FIELD_NAME } from 'types/signUp';
import { FIELD_NAME as FIELD } from 'types/login';
import { login } from 'constants/errors';
import { IFormLoginData } from 'types/login';
import AuthService from 'services/auth.service';
import LoaderWithBg from 'components/loader/loader';
import { useUser } from 'hooks/user/useUser';
import { ModalState, useModalContext } from 'context/modal/modalContext';
import { useTranslation } from 'react-i18next';
import { useGame } from 'hooks/playGames/useGame';
import { MODAL_TYPES } from 'types/modals';
import { setUserData } from 'redux/actions/userDataAction';
import { useDispatch } from 'react-redux';
import { RESULT } from 'types/common';
import Hero from 'assets/heroImg/hero.webp';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'context/auth/authContext';
import { useUserData } from 'hooks/user/useUserData';
import styles from './login.module.scss';

const initialData = {
  login: '',
  password: '',
};

const Input = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(2),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    border: '1px #fff solid',
    fontSize: 15,
    width: '100%',
    padding: '8px 12px',
    transition: theme.transitions.create(['border-color']),
    // color: common.mainOrange,
    '&:focus': {
      borderColor: common.mainOrange,
    },
  },
}));

interface LoginModalProps {
  isMobile?: boolean;
  onClose?: (type: string) => void;
  onSwitchComponent?: (type: string) => void;
  props?: ModalState;
}

export default function LoginModal({
  onClose,
  onSwitchComponent,
  props,
  isMobile,
}: LoginModalProps) {
  const { changeGameFavoriteStatus } = useGame();
  const { setHasAccessToken } = useAuthContext();
  const { getUserWheelDiff } = useUserData();
  const { addUser } = useUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t: loginAndSignupT } = useTranslation('loginAndSignup');
  const { isModalOpened, changeModalContent } = useModalContext();
  const [formData, setFormData] = useState<IFormLoginData>(initialData);
  const [errors, setErrors] = useState<IErrors>({} as IErrors);
  const [showLoader, setShowLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [apiError, setApiError] = useState<string>('');
  const [dataMessage, setDataMessage] = useState<string>('');
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleOnClickLink = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    if (isModalOpened) {
      changeModalContent?.(FIELD.SIGN_UP, null);
    } else {
      onSwitchComponent?.(FIELD.SIGN_UP);
      if (!isMobile) {
        onClose?.(FIELD.LOGIN);
      }
    }
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: string,
  ) => {
    const { value } = e.target;
    if (value === '') {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [name]: _, ...err } = errors;
      setErrors(err);
    }
    if (apiError) setApiError('');
    const data = { ...formData, [name]: value } as IFormData;
    setFormData(data);
  };

  const handleOnBlur = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: string,
  ) => {
    const { value } = e.target;
    if (value) {
      const data = formData[name];
      const isValid = isValidValue(data, name);
      if (isValid && errors[name]) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [name]: _, ...err } = errors;
        setErrors(err);
      } else if (!isValid) {
        setErrors((err) => ({ ...err, [name]: name }));
      }
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isValidate = isEmpty(errors);
    const isEmptyData = hasEmptyProps(formData);
    if (isValidate && !isEmptyData) {
      setShowLoader(true);
      try {
        const {
          data: { data, status },
        } = await AuthService.login(formData);
        if (status === 200) {
          setShowLoader(false);
          /**TODO remove one of methods for storing user data */
          addUser(data);
          dispatch(setUserData(data.user));
          const header = generateHeaders(data.accessToken);
          if (props?.isOpenGame) {
            changeModalContent?.(MODAL_TYPES.CHOOSE_BALANCE, { ...props?.data });
          } else if (props?.data?.isOpenModal) {
            changeModalContent?.(props?.data?.modalName, { ...props?.data });
          } else if (props?.isFavorite) {
            const body = { isFavorite: props?.data.isFavorite };
            const res = await changeGameFavoriteStatus(
              props?.data.providerId,
              props?.data.gameId,
              body,
              header,
            );
            if (res?.status === 200) {
              setHasAccessToken(true);
              onClose?.(FIELD_NAME.LOGIN);
            }
          } else if (props?.data?.redirect) {
            navigate(props?.data?.redirect);
            onClose?.(FIELD_NAME.LOGIN);
          } else {
            await getUserWheelDiff(header);
            onClose?.(FIELD_NAME.LOGIN);
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        const {
          response: {
            data: { message, data },
          },
        } = error;

        setApiError(loginAndSignupT(login[message]));
        if (data) {
          setDataMessage(data);
        }
        setShowLoader(false);
      }
    }
  };

  return (
    <>
      {showLoader && <LoaderWithBg isOpen={showLoader} />}
      <Container component="main" maxWidth="xl" className={styles.modal_login}>
        <Box className={cx({ [styles.is_mobile]: isMobile })}>
          <Box className={cx(styles.header, { [styles.is_mobile]: isMobile })}>
            <Typography className={styles.title} textAlign="left" sx={{ mb: 2, ml: 1 }}>
              {loginAndSignupT('login')}
            </Typography>
          </Box>
          <Box component="form" onSubmit={handleSubmit} className={styles.main} sx={{ mt: 1 }}>
            <Box className={styles.input_content}>
              <Box className={styles.left_side}>
                <FormControl sx={{ mb: 3 }}>
                  <InputLabel shrink htmlFor="login-input">
                    {errors?.login ? (
                      <Typography sx={{ color: common.error, fontWeight: 600 }}>
                        {loginAndSignupT('loginError')}
                      </Typography>
                    ) : (
                      ` ${loginAndSignupT('loginLabel')} *`
                    )}
                  </InputLabel>
                  <Input
                    placeholder={loginAndSignupT('loginPlaceholder')}
                    fullWidth
                    error={!!errors?.login}
                    id="login-input"
                    required
                    onChange={(e) => handleOnChange(e, FIELD_NAME.LOGIN)}
                    onBlur={(e) => handleOnBlur(e, FIELD_NAME.LOGIN)}
                    className={cx({ [styles.error]: !!errors?.login })}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                  />
                </FormControl>
                <FormControl sx={{ mb: 3 }}>
                  <InputLabel shrink htmlFor="password">
                    {errors?.password ? (
                      <Typography sx={{ color: common.error, fontWeight: 600 }}>
                        {loginAndSignupT('passwordError')}
                      </Typography>
                    ) : (
                      `${loginAndSignupT('password')}*`
                    )}
                  </InputLabel>
                  <Input
                    placeholder={loginAndSignupT('passPlaceholder')}
                    fullWidth
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    required
                    onChange={(e) => handleOnChange(e, FIELD_NAME.PASSWORD)}
                    onBlur={(e) => handleOnBlur(e, FIELD_NAME.PASSWORD)}
                    endAdornment={
                      <InputAdornment position="end" sx={{ m: 0, width: 0 }}>
                        <IconButton
                          onClick={handleClickShowPassword}
                          edge="end"
                          sx={{
                            color: `${errors?.password ? common.error : '#fff'}`,
                            width: 10,
                            right: 28,
                          }}
                        >
                          {showPassword ? (
                            <VisibilityOffOutlinedIcon />
                          ) : (
                            <VisibilityOutlinedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    className={cx({ [styles.error]: !!errors?.password })}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                  />
                </FormControl>
              </Box>
            </Box>
            <Box className={styles.action_content}>
              {apiError && (
                <Result
                  style={{ mb: 2, mt: 2, maxWidth: '300px' }}
                  message={apiError + ` ` + dataMessage}
                  type={RESULT.ERROR}
                  isShow
                />
              )}

              <Box className={styles.action}>
                <Button size="large" type="submit" fullWidth className={styles.login_btn}>
                  {loginAndSignupT('login')}
                </Button>
                <Typography className={styles.have_acount} textAlign="left" sx={{ mb: 2 }}>
                  {loginAndSignupT('dontHaveAccount')}
                  <Link href="" className={styles.link} onClick={(e) => handleOnClickLink(e)}>
                    {loginAndSignupT('signup')}
                  </Link>
                </Typography>
              </Box>
              <Box className={styles.info}>
                <Box>
                  <Link href="/" className={styles.link}>
                    {loginAndSignupT('forgotPassword')}
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={styles.modal_bg}>
          <img src={Hero} alt="hero" width="550" />
        </Box>
      </Container>
    </>
  );
}
