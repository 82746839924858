import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { RESULT } from 'types/common';
import { useNavigate } from 'react-router-dom';
import { routing } from 'constants/routing';
import styles from './paymentStatus.module.scss';

type paymentStatus = 'success' | 'failed';

interface PaymentStatusProps {
  status: paymentStatus;
}

function PaymentStatus({ status }: PaymentStatusProps) {
  const { t } = useTranslation('payment');
  const navigate = useNavigate();

  if (status === 'failed') {
    navigate(`${routing.home}`, {
      state: {
        paymentStatus: 'warning',
        contentText: t(RESULT.REQUISITES_ERROR),
      },
    });
  }

  return (
    <Box className={cx(styles.content, { [styles.isSuccess]: status === RESULT.SUCCESS })}>
      {t(`${status}Deposit`)}
    </Box>
  );
}

export default PaymentStatus;
