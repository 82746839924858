import Button from '@mui/material/Button';
import cx from 'classnames';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useEffect, useState } from 'react';
import info from 'assets/icons/info.svg';
import { hasEmptyProps, isEmpty } from 'utils/utils';
import common from 'styles/colors.module.scss';
import { CurrencyCheckboxes } from 'components/checkbox/checkboxes';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputBase,
  InputLabel,
  Link,
  Switch,
  TextField,
  styled,
} from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { isValidValue } from 'utils/helpers/inputHelper';
import { IFormData, IErrors, FIELD_NAME, ICheckBoxes } from 'types/signUp';
import { FIELD_NAME as FIELD } from 'types/login';
import AuthService from 'services/auth.service';
import { routing } from 'constants/routing';
import { useNavigate } from 'react-router-dom';
import { SITE_ID } from 'services/site.service';
import LoaderWithBg from 'components/loader/loader';
import Result from 'components/alert/alert';
import { useModalContext } from 'context/modal/modalContext';
import { useTranslation } from 'react-i18next';
import { signup } from 'constants/errors';
import { useUser } from 'hooks/user/useUser';
import { setUserData } from 'redux/actions/userDataAction';
import logoMobile from 'assets/logo/logo.webp';
import Hero from 'assets/heroImg/hero.webp';
import { useDispatch, useSelector } from 'react-redux';
import { RESULT } from 'types/common';
import DialogAlert from 'components/dialog/dialogAlert';
import { isAlphanumeric, isValidPhonePattern } from 'utils/validations/validationTypes';
import InputMask from 'react-input-mask';
import styles from './signup.module.scss';

const initialData = {
  login: '',
  password: '',
  confirm: '',
  email: '',
  currencyId: 0,
  phone: '',
  promo: null,
  terms: true,
};

const Input = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(2),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    border: '1px #fff solid',
    fontSize: 15,
    width: '100%',
    padding: '8px 12px',
    transition: theme.transitions.create(['border-color']),
    '&:focus': {
      borderColor: common.mainOrange,
    },
  },
}));

interface SignupModalProps {
  isMobile?: boolean;
  onClose: (type: string) => void;
  onSwitchComponent?: (type: string) => void;
}

const fields = {
  [FIELD_NAME.PHONE]: true,
  [FIELD_NAME.PROMO_CODE]: true,
  [FIELD_NAME.CONFIRM_PASS]: true,
  [FIELD_NAME.EMAIL]: true,
  [FIELD_NAME.LOGIN]: true,
  [FIELD_NAME.PASSWORD]: true,
};

export default function SignupModal({ onClose, onSwitchComponent, isMobile }: SignupModalProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { addUser } = useUser();
  const { siteCurrencies } = useSelector((state: any) => state.siteCurrencies) as {
    siteCurrencies: ICheckBoxes[];
  };
  const { t: loginAndSignupT } = useTranslation('loginAndSignup');
  const { t: validationT } = useTranslation('validation');
  const [phoneNumber, setPhoneNumber] = useState('');
  const { isModalOpened, changeModalContent } = useModalContext();
  const [formData, setFormData] = useState<IFormData>(initialData);
  const [errors, setErrors] = useState<IErrors>({} as IErrors);
  const [validFields, setValidFields] = useState({ ...fields });
  const [showLoader, setShowLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const [showPromoCode, setShowPromoCode] = useState(false);
  const [apiError, setApiError] = useState<string>('');
  const [open, setOpen] = useState(false);
  let timeOutId: ReturnType<typeof setTimeout>;

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfPassword = () => setShowConfPassword((show) => !show);

  const handleOnClickLink = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    if (isModalOpened) {
      changeModalContent?.(FIELD.LOGIN, null);
    } else {
      onSwitchComponent?.(FIELD.LOGIN);
      if (!isMobile) {
        onClose?.(FIELD.SIGN_UP);
      }
    }
  };

  const handleOnSwicth = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    if (!checked) {
      const data = { ...formData, promo: null };
      setFormData(data);
    }

    setShowPromoCode(checked);
  };

  const handleCurrencyName = (id: number) => {
    const data = { ...formData, currencyId: id } as IFormData;
    setFormData(data);

    const { currencyId: _, ...err } = errors;
    setErrors(err);
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: string,
  ) => {
    const { value } = e.target;
    if (value === '') {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [name]: _, ...err } = errors;
      setErrors(err);
    }

    const data = { ...formData, [name]: value } as IFormData;
    setFormData(data);
  };

  const validate = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, name: string) => {
    const { value } = e.target;
    setValidFields((fields) => ({
      ...fields,
      [name]: isValidValue(value, name),
    }));
  };

  const handleOnBlur = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: string,
  ) => {
    const { value } = e.target;
    if (value) {
      const data = formData[name];

      const isValid = isValidValue(data, name, FIELD.SIGN_UP);
      if (name === 'confirm') {
        if (data !== formData['password']) {
          setErrors((err) => ({ ...err, [name]: name }));
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { [name]: _, ...err } = errors;
          setErrors(err);
        }
      } else {
        if (isValid && errors[name]) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { [name]: _, ...err } = errors;
          setErrors(err);
        } else if (!isValid) {
          setErrors((err) => ({ ...err, [name]: name }));
        }
      }
    }
  };

  const handleOnChangePhone = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    setPhoneNumber(value);
    const data = { ...formData, [FIELD_NAME.PHONE]: value } as IFormData;
    setFormData(data);
  };

  const handleOnClose = () => {
    setOpen(false);
    navigate(routing.home);
    onClose(FIELD.SIGN_UP);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (siteCurrencies.length === 1) {
      formData.currencyId = +siteCurrencies[0].id;
    }

    const isValidate =
      isEmpty(errors) &&
      validFields[FIELD_NAME.PHONE] &&
      (showPromoCode ? validFields[FIELD_NAME.PROMO_CODE] : true);
    const isEmptyData = showPromoCode ? hasEmptyProps(formData) : hasEmptyProps(formData, 'promo');

    if (isValidate && !isEmptyData && formData.terms && formData.currencyId !== 0) {
      const { email, login, password, phone, currencyId, promo } = formData;
      const body = {
        email,
        login,
        password,
        phone,
        currencyId,
        promo,
        siteId: SITE_ID,
      };
      try {
        setShowLoader(true);
        const {
          data: { data, status, message },
        } = await AuthService.signup(body);
        if (status === 201) {
          addUser(data);
          dispatch(setUserData(data.user));
          setOpen(true);
          timeOutId = setTimeout(() => {
            handleOnClose();
          }, 2000);
        } else if (status === 404) {
          setApiError(validationT(signup[message]));
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        const {
          response: {
            data: { message, status },
          },
        } = error;

        if (status === 409) {
          setApiError(validationT(signup.USER_ALREADY_EXIST));
        } else {
          setApiError(validationT(signup[message]));
        }
      } finally {
        setShowLoader(false);
      }
    } else {
      if (formData.currencyId === undefined || formData.currencyId === 0) {
        setErrors((err) => {
          return { ...err, currencyId: 'currencyId' };
        });
      }
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeOutId);
    };
  }, []);

  return (
    <>
      {showLoader && <LoaderWithBg isOpen={showLoader} />}
      <Container
        component="main"
        maxWidth="xl"
        className={cx(styles.modal_signup, { [styles.is_modal]: isModalOpened })}
      >
        <Box className={styles.box}>
          <Box className={cx(styles.header, { [styles.is_modal]: isModalOpened })}>
            <Typography className={styles.title} textAlign="left">
              {loginAndSignupT('signup')}
            </Typography>
          </Box>
          <Box component="form" onSubmit={handleSubmit} className={styles.main}>
            <Box className={cx(styles.input_content, { [styles.is_modal]: isModalOpened })}>
              <Box className={cx({ [styles.left_side]: isModalOpened })}>
                <FormControl sx={{ mb: 3 }}>
                  <InputLabel shrink htmlFor="login-input">
                    {errors?.login ? (
                      !isAlphanumeric(formData.login) ? (
                        <Typography sx={{ color: common.error, fontWeight: 600 }}>
                          {validationT('onlyAlphanumeric')}
                        </Typography>
                      ) : (
                        <Typography sx={{ color: common.error, fontWeight: 600 }}>
                          {validationT('minAndMaxLength', { minLength: 6, maxLength: 15 })}
                        </Typography>
                      )
                    ) : (
                      `${loginAndSignupT('loginLabel')} *`
                    )}
                  </InputLabel>
                  <Input
                    placeholder={loginAndSignupT('loginPlaceholder')}
                    error={!!errors?.login}
                    id="login-input"
                    required
                    onChange={(e) => handleOnChange(e, FIELD_NAME.LOGIN)}
                    onBlur={(e) => handleOnBlur(e, FIELD_NAME.LOGIN)}
                    className={cx({ [styles.error]: !!errors?.login })}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                  />
                </FormControl>
                <FormControl sx={{ mb: 3 }}>
                  <InputLabel shrink htmlFor="email-input">
                    {errors?.email ? (
                      <Typography sx={{ color: common.error, fontWeight: 600 }}>
                        {formData.email.length > 34
                          ? validationT('maxLength', { length: 35 })
                          : validationT('invalidEmail')}
                      </Typography>
                    ) : (
                      `${loginAndSignupT('email')} *`
                    )}
                  </InputLabel>
                  <Input
                    id="email-input"
                    placeholder={loginAndSignupT('emailPlaceholder')}
                    required
                    onChange={(e) => handleOnChange(e, FIELD_NAME.EMAIL)}
                    onBlur={(e) => handleOnBlur(e, FIELD_NAME.EMAIL)}
                    className={cx({ [styles.error]: !!errors?.email })}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                  />
                </FormControl>
                <FormControl sx={{ marginBottom: '0!important' }}>
                  <InputLabel shrink htmlFor="phone-input">
                    {errors?.phone || !validFields[FIELD_NAME.PHONE] ? (
                      <Typography sx={{ color: common.error, fontWeight: 600 }}>
                        {validationT('invalidPhoneNumber')}
                      </Typography>
                    ) : (
                      `${loginAndSignupT('phoneNumber')} *`
                    )}
                  </InputLabel>
                  <InputMask
                    id="phone-input"
                    required
                    mask="+7(999)-999-99-99"
                    value={phoneNumber}
                    onChange={handleOnChangePhone}
                    onBlur={(e) => validate(e, FIELD_NAME.PHONE)}
                  >
                    {() => (
                      <TextField
                        sx={{ mt: 2 }}
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={phoneNumber}
                        onChange={handleOnChangePhone}
                        placeholder="+7(900)-000-00-00"
                        error={!validFields[FIELD_NAME.PHONE]}
                      />
                    )}
                  </InputMask>
                </FormControl>
                <FormControl sx={{ mb: 3 }}>
                  <InputLabel shrink htmlFor="password">
                    {errors?.password ? (
                      <Typography sx={{ color: common.error, fontWeight: 600 }}>
                        {validationT('minLength', { length: 8 })}
                      </Typography>
                    ) : (
                      `${loginAndSignupT('password')} *`
                    )}
                  </InputLabel>
                  <Input
                    id="password"
                    placeholder={loginAndSignupT('passPlaceholder')}
                    type={showPassword ? 'text' : 'password'}
                    required
                    onChange={(e) => handleOnChange(e, FIELD_NAME.PASSWORD)}
                    onBlur={(e) => handleOnBlur(e, FIELD_NAME.PASSWORD)}
                    endAdornment={
                      <InputAdornment position="end" sx={{ m: 0, width: 0 }}>
                        <IconButton
                          onClick={handleClickShowPassword}
                          edge="end"
                          sx={{
                            color: `${errors?.password ? common.error : '#fff'}`,
                            width: 10,
                            right: 28,
                          }}
                        >
                          {showPassword ? (
                            <VisibilityOffOutlinedIcon />
                          ) : (
                            <VisibilityOutlinedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    className={cx({ [styles.error]: !!errors?.password })}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                  />
                </FormControl>
                <FormControl sx={{ mb: 3 }}>
                  <InputLabel shrink htmlFor="confirmpassword">
                    {errors?.confirm ? (
                      <Typography sx={{ color: common.error, fontWeight: 600 }}>
                        {validationT('invalidConfirmPass')}
                      </Typography>
                    ) : (
                      `${loginAndSignupT('confirmPass')}*`
                    )}
                  </InputLabel>
                  <Input
                    id="confirmpassword"
                    placeholder={loginAndSignupT('confirmPass')}
                    type={showConfPassword ? 'text' : 'password'}
                    required
                    className={cx({ [styles.error]: !!errors?.confirm })}
                    onChange={(e) => handleOnChange(e, FIELD_NAME.CONFIRM_PASS)}
                    onBlur={(e) => handleOnBlur(e, FIELD_NAME.CONFIRM_PASS)}
                    endAdornment={
                      <InputAdornment position="end" sx={{ m: 0, width: 0 }}>
                        <IconButton
                          onClick={handleClickShowConfPassword}
                          edge="end"
                          sx={{
                            color: `${errors?.confirm ? common.error : '#fff'}`,
                            width: 10,
                            right: 28,
                          }}
                        >
                          {showConfPassword ? (
                            <VisibilityOffOutlinedIcon />
                          ) : (
                            <VisibilityOutlinedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    inputProps={{
                      autoComplete: 'off',
                    }}
                  />
                </FormControl>
                <Box className={cx(styles.switcher, { [styles.promoCode]: showPromoCode })}>
                  <FormControlLabel
                    className={styles.label}
                    control={
                      <Switch size="small" checked={showPromoCode} onChange={handleOnSwicth} />
                    }
                    label={loginAndSignupT('promoCodeLabel')}
                  />
                  {showPromoCode ? (
                    <FormControl sx={{ mb: 3 }}>
                      <InputLabel shrink htmlFor="promoCode-input">
                        {errors?.promoCode || !validFields[FIELD_NAME.PROMO_CODE] ? (
                          <Typography sx={{ color: common.error, fontWeight: 600 }}>
                            {validationT('invalidPromo')}
                          </Typography>
                        ) : (
                          `${loginAndSignupT('promoCode')}`
                        )}
                      </InputLabel>
                      <Input
                        id="promoCode-input"
                        required
                        onChange={(e) => handleOnChange(e, FIELD_NAME.PROMO_CODE)}
                        onBlur={(e) => validate(e, FIELD_NAME.PROMO_CODE)}
                        className={cx({
                          [styles.error]:
                            !!errors?.promoCode || !validFields[FIELD_NAME.PROMO_CODE],
                        })}
                      />
                    </FormControl>
                  ) : null}
                </Box>
              </Box>
            </Box>
            <Box className={styles.action_content}>
              <Box className={styles.info}>
                <Box sx={{ justifyContent: 'start' }}>
                  {errors?.currencyId ? (
                    <Typography sx={{ color: common.error, fontSize: '14px' }}>
                      {loginAndSignupT('chooseCurr')}
                    </Typography>
                  ) : (
                    <Typography className={styles.currency} component="div">
                      {loginAndSignupT('currency')}
                    </Typography>
                  )}

                  <CurrencyCheckboxes onChange={handleCurrencyName} />
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Box className={cx(styles.info_text, { [styles.hidden]: !isModalOpened })}>
                    <img src={info} alt="logo" width={17} height={16} />
                    <Typography component="span">{loginAndSignupT('requiredInfo')}</Typography>
                  </Box>
                  <FormControlLabel
                    sx={{ mr: 3 }}
                    control={
                      <Checkbox
                        checked={formData.terms}
                        sx={{
                          '& .MuiSvgIcon-root': { fontSize: 20, color: '#fff' },
                          m: 'auto',
                          p: 1,
                        }}
                      />
                    }
                    label={
                      <label className={styles.label}>
                        {loginAndSignupT('agree')}
                        <Link href="/terms" className={styles.link}>
                          {loginAndSignupT('terms')}
                        </Link>
                        {loginAndSignupT('and')}
                        <Link href="/conditions" className={styles.link}>
                          {loginAndSignupT('conditions')}
                        </Link>
                        {loginAndSignupT('dataProtection')}
                      </label>
                    }
                    labelPlacement="end"
                  />
                </Box>
              </Box>
              <Box sx={{ mt: 2, width: '100%' }}>
                {apiError && (
                  <Result style={{ width: '100%' }} message={apiError} type={RESULT.ERROR} isShow />
                )}
              </Box>
              <Box className={styles.action}>
                <Button
                  size="large"
                  type="submit"
                  fullWidth
                  className={cx(styles.sign_btn, { [styles.is_modal]: isModalOpened })}
                >
                  {loginAndSignupT('signup')}
                </Button>
                <Typography className={styles.have_acount} textAlign="left" sx={{ mb: 4 }}>
                  {loginAndSignupT('hasAccount')}
                  <Link href="" className={styles.link} onClick={(e) => handleOnClickLink(e)}>
                    {loginAndSignupT('login')}
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={styles.modal_bg}>
          <img className={styles.logo} src={logoMobile} alt="logo" width="160px" />
          <img className={styles.hero} src={Hero} alt="hero" width="550px" />
        </Box>
        <Box>
          <DialogAlert
            content={loginAndSignupT('registeredText')}
            isOpen={open}
            onClose={handleOnClose}
            severity={'success'}
          />
        </Box>
      </Container>
    </>
  );
}
