import { Box, Button, Typography } from '@mui/material';
import common from 'styles/colors.module.scss';
import _color from 'styles/colors.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { routing } from 'constants/routing';
import { usePayment } from 'hooks/payment/usePayment';
import { useAuthContext } from 'context/auth/authContext';
import { links } from 'constants/common';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import styles from './manualPayment.module.scss';

const init = {
  amount: null,
  paymentId: null,
  requisites: null,
};

const SUCCESS = 'get_pay_in_process_details_success';
const PENDING = 'get_pay_in_process_details_pending';
const CANCElED = 'get_pay_in_process_details_canceled';

function ManualPaymentConfirm() {
  const { state } = useLocation();
  const { t } = useTranslation('payment');
  const navigate = useNavigate();
  const { payInProccesConfirm } = usePayment();
  const { hasAccessToken } = useAuthContext();
  const [hoursReq, setHoursReq] = useState('');
  const [data, setData] = useState({ ...init });
  const controlTime = useRef(0);

  let intervalId_Req: ReturnType<typeof setInterval>;
  let timeOutId: ReturnType<typeof setTimeout>;

  const countDownReq = (distance: number) => {
    let time = distance;
    intervalId_Req = setInterval(() => {
      time = time - 1;
      if (time === 0) {
        clearInterval(intervalId_Req);
        setHoursReq(moment.utc(0 * 1000).format('mm:ss'));
      }
      setHoursReq(moment.utc(time * 1000).format('mm:ss'));
    }, 1000);
  };

  const makeRequest = async (id: string | null) => {
    if (id) {
      const result = await payInProccesConfirm(id);
      if (result?.status === 200 && result?.message === PENDING) {
        //pendinq
      } else if (result?.status === 200 && result?.message === SUCCESS) {
        redirectToWithDelay('success', t('successDeposit'));
      } else if (result?.status === 403 && result?.message === CANCElED) {
        redirectToWithDelay('error', t('failedDeposit'));
      }
    }
  };

  const redirectTo = () => {
    window.open(links.TG_SUPPORT, '_blank');
  };

  const redirectToWithDelay = (status: string, contentText: string) => {
    clearInterval(intervalId_Req);

    timeOutId = setTimeout(() => {
      navigate(`${routing.home}`, {
        state: {
          paymentStatus: status,
          contentText,
        },
      });
    }, 4000);
  };

  useEffect(() => {
    if (state) {
      const { paymentId } = state;
      if (paymentId && hasAccessToken) {
        makeRequest(paymentId);
        countDownReq(600);
        setData(state);
      }
    } else {
      navigate(`${routing.home}`);
    }
  }, [state, hasAccessToken]);

  useEffect(() => {
    ++controlTime.current;
    if (!(controlTime.current % 10)) {
      makeRequest(data.paymentId ?? state.paymentId);
    }
  }, [hoursReq]);

  useEffect(() => {
    return () => {
      clearInterval(intervalId_Req);
      clearTimeout(timeOutId);
    };
  }, []);

  return (
    <>
      <Box className={styles.content}>
        <Box className={styles.req_body}>
          <Box className={styles.req_title}>
            <Typography component="h1">
              {'Не закрывайте эту страницу, иначе транзакция может быть утеряна!'}
            </Typography>
          </Box>
          <Box className={styles.req_content}>
            <Typography component="p">
              {`Пожалуйста, подождите, транзакция на сумму ${
                data?.amount ?? ''
              } рублей, оправленная в  ${data?.requisites ?? ''}  через
              метод СБП будет потвержден в течение`}
            </Typography>
          </Box>

          <Typography
            sx={{
              color: common.error,
              fontWeight: 600,
              textAlign: 'center',
              m: 2,
              fontSize: '1.8rem',
            }}
          >
            {hoursReq}
          </Typography>
          <Box className={styles.actions}>
            <Button fullWidth className={styles.grey} onClick={redirectTo}>
              Написать в поддержку
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ManualPaymentConfirm;
