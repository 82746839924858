import { Box, IconButton, InputBase, Pagination, Paper, Stack, Typography } from '@mui/material';
import SelectSubProvider from 'components/select/select';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import color from 'styles/colors.module.scss';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import GameCard from 'components/gameCard/gameCard';
import { useSubProviderGames } from 'hooks/subProviderGames/useSubProviderGames';
import { makeStyles } from '@mui/styles';
import styles from './subProvider.module.scss';
import { useAuthContext } from 'context/auth/authContext';

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root.Mui-selected, & .MuiPaginationItem-root.Mui-selected:hover': {
      color: color.mainOrange,
      backgroundColor: 'unset',
    },
  },
  paper: {
    height: '40px',
    border: 'thin solid white',
    backgroundColor: 'unset!important',
    '& svg': {
      fill: 'white',
    },
  },
}));

function SubProvider() {
  const { t } = useTranslation('common');
  const params = useParams();
  const [gameList, setGameList] = useState([]);
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [selectedProviderId, setSelecetedProviderId] = useState(params.id);
  const [debouncedValue, setDebouncedValue] = useState('');
  const { user } = useAuthContext();
  const { getAllSubProviderGames, totalPages, isLoading } = useSubProviderGames();
  const providersWithoutGames = useSelector<any>((state) => state.providersWithoutGames) as any;
  const classes = useStyles();

  const { device } = useSelector<any>((state) => state.device) as {
    device: string;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const changeProvider = (value: string) => {
    setSelecetedProviderId(value);
    setPage(1);
    setDebouncedValue('');
    setName('');
  };

  const setNameValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    setName(value);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    setPage(1);
    const delay = 500;
    const timeoutId = setTimeout(() => {
      setDebouncedValue(name);
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [name]);

  useEffect(() => {
    const forPublic = !user ? '/public?' : undefined;
    let query = debouncedValue ? `&name=${debouncedValue}` : '';
    const deviceQuery = device === 'Mobile' ? `&isMobile=true` : `&isDesktop=true`;
    selectedProviderId ? (query += `&subProviderId=${selectedProviderId}`) : null;
    const getData = async () => {
      query += `&page=${page}${deviceQuery}`;
      const result = await getAllSubProviderGames(query, forPublic);
      if (result?.status) {
        const {
          data: { games },
        } = result;
        setGameList(games);
      }
    };

    getData();
  }, [debouncedValue, page, selectedProviderId]);

  useEffect(() => {
    setSelecetedProviderId(params.id);
    setPage(1);
  }, [params]);

  return (
    <Box>
      <Box className={styles.topSection} m={5}>
        <Paper onSubmit={handleSubmit} className={classes.paper} component="form">
          <InputBase
            size="small"
            fullWidth
            sx={{ ml: 1, flex: 1 }}
            placeholder={t('searchGame')}
            value={name}
            onChange={(e) => setNameValue(e)}
            inputProps={{ 'aria-label': 'search game' }}
          />
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
        <Box className={styles.select}>
          <SelectSubProvider
            initValue={selectedProviderId ? selectedProviderId + '' : undefined}
            subProviderList={providersWithoutGames}
            width={'200px'}
            onSelect={changeProvider}
          />
        </Box>
      </Box>

      {isLoading ? (
        <Box sx={{ minHeight: '600px' }}>
          <Typography
            sx={{ ml: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            {t('loading')}
          </Typography>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box className={styles.games}>
            {gameList?.map(
              ({ image, id, providerId, isFavorite, isUnliked }: any, index: number) => {
                return (
                  <GameCard
                    key={index}
                    imageUrl={image}
                    gameId={id}
                    className={styles.gameItem}
                    providerId={providerId}
                    isFavorite={isFavorite}
                    isUnliked={isUnliked}
                  />
                );
              },
            )}
          </Box>
          <Stack spacing={2} sx={{ m: 4 }}>
            <Pagination
              count={totalPages}
              className={classes.ul}
              hideNextButton
              hidePrevButton
              page={page}
              onChange={handlePageChange}
            />
          </Stack>
        </Box>
      )}
    </Box>
  );
}

export default SubProvider;
