import { useEffect, useLayoutEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import ResponsiveNavBar from 'components/navBar/navBar';
import { Box, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { light } from 'styles/theme/light';
import NoMatch from 'components/notFound/notFound';
import { routing } from 'constants/routing';
import { useSiteConfig } from 'hooks/configHooks/useConfig';
import { MainModal } from 'components/modals/mainModal';
import Terms from 'components/terms/terms';
import { io, Socket } from 'socket.io-client';
import { ClientToServerEvents, ServerToClientEvents } from 'types/socket/socket';
import { useAuthContext } from 'context/auth/authContext';
import useDeviceDetection from 'hooks/deviceDetection/useDeviceDetection';
import { useDispatch, useSelector } from 'react-redux';
import { useModalContext } from 'context/modal/modalContext';
import Footer from 'components/footer/footer';
import AboutUs from 'components/aboutUs/aboutUs';
import { GamesPage } from 'components/pageLayout/games/games';
import { SlotsPage } from 'components/pageLayout/slots/slots';
import { CategoryPage } from 'components/pageLayout/category/category';
import { PlayGame } from 'components/pageLayout/playGame/playGame';
import { GameDemo } from 'components/pageLayout/gameDemo/gameDemo';
import SubProvider from 'components/pageLayout/subProvider/subProvider';
import { wsURL } from 'ws-connection';
import Statistics from 'components/pageLayout/statistics/statistics';
import { setUserData, updateUserBalance } from 'redux/actions/userDataAction';
import { BonusesPage } from 'components/pageLayout/bonuses/bonuses';
import BonusTerms from 'components/pageLayout/bonusTerms/bonusTerms';
import { User } from 'types/user';
import Payments from 'components/pageLayout/payments/payments';
import { WheelPage } from 'components/wheel/wheel';
import { useBalance } from 'hooks/user/useBalance';
import { useStorage } from 'hooks/authHooks/useStorage';
import Faq from 'components/pageLayout/faq/faq';
import LoaderWithLogo from 'components/loader/loaderWithLogo';
import PaymentStatus from 'components/pageLayout/paymentFailed/paymentStatus';
import ManualPayment from 'components/pageLayout/manualPayment/manualPayment';
import AutoSnackbar from 'components/snackBar/snackBar';
import ManualPaymentConfirm from 'components/pageLayout/manualPayment/manualPaymentWhenConfirm';
import DialogAlert, { severityType } from 'components/dialog/dialogAlert';
import './App.scss';

export const socket: Socket<ServerToClientEvents, ClientToServerEvents> = io(wsURL as string);

interface SensitiveRouteProps {
  user: User | null;
  children: React.ReactNode;
}

const SensitiveRoute = ({ user, children }: SensitiveRouteProps) => {
  if (!user) return <Navigate replace to={routing.home} />;
  return <>{children}</>;
};

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state: stateLocale } = useLocation();
  const [contentText, setContentText] = useState('');
  const [severity, setSeverity] = useState<severityType>('success');
  useDeviceDetection();
  const { isLoading: isLoadingConfigs, getSiteConfig } = useSiteConfig();
  const { isLoading, getUserBalance } = useBalance();
  const { updateData } = useStorage();
  const [isOpenDialogAlert, setIsOpenDialogAlert] = useState(false);
  const { user } = useAuthContext();
  const { state, modalType, isModalOpened, closeModal } = useModalContext();
  let timeOutId: ReturnType<typeof setTimeout>;

  const { categories } = useSelector<any>((state) => state.categories) as {
    categories: any;
  };

  const handleOnClose = () => {
    setIsOpenDialogAlert(false);
    navigate(`${routing.home}`, { replace: true });
    setContentText('');
  };

  const hideNotificationDialog = () => {
    if (stateLocale?.contentText && stateLocale?.paymentStatus) {
      timeOutId = setTimeout(() => {
        handleOnClose();
        updateBalance();
      }, 3000);
    }
  };

  const updateBalance = async () => {
    const res = await getUserBalance();
    if (res) {
      dispatch(updateUserBalance(res.data));
      updateData({ balance: res.data });
    }
  };

  useEffect(() => {
    socket.on('closeSession', () => {
      navigate(`${routing.home}`);
    });

    getSiteConfig();
    if (user) {
      updateBalance();
      dispatch(setUserData(user.user));
    }

    return () => {
      clearTimeout(timeOutId);
    };
  }, []);

  useEffect(() => {
    if (user) {
      socket.emit('user', { userId: user.user.id });
    }
  }, [user]);

  useEffect(() => {
    if (stateLocale && user) {
      setIsOpenDialogAlert(stateLocale?.paymentStatus);
      setContentText(stateLocale?.contentText);
      setSeverity(stateLocale?.paymentStatus);
      hideNotificationDialog();
    }
  }, [stateLocale, user]);

  useLayoutEffect(() => {
    const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
    if (!prefersReducedMotion) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return (
    <ThemeProvider theme={light}>
      <CssBaseline />
      <Box className="App">
        <ResponsiveNavBar />
        <AutoSnackbar />
        <hr className="divider" />
        <Box className="App-container">
          {isLoading || isLoadingConfigs || (!categories?.length && location.pathname === '/') ? (
            <Box
              sx={{ minHeight: '800px', position: 'fixed', top: '50%', left: '50%', zIndex: 1300 }}
            >
              <LoaderWithLogo isOpen={isLoading || isLoadingConfigs || !categories?.length} />
            </Box>
          ) : null}
          <>
            <Box sx={{ minHeight: '800px' }}>
              <Routes>
                <Route path={routing.home} element={<GamesPage />} />
                <Route path={routing.slots} element={<SlotsPage />} />
                <Route path={routing.wheel} element={<WheelPage />} />
                <Route path={routing.bonuses} element={<BonusesPage />} />
                <Route path={routing.faq} element={<Faq />} />
                <Route path={routing.terms} element={<Terms />} />
                <Route path={`${routing.category}/:id`} element={<CategoryPage />} />
                <Route path={`${routing.provider}/:id`} element={<SubProvider />} />
                <Route path={routing.aboutUs} element={<AboutUs />} />
                <Route path={routing.bonusTerms} element={<BonusTerms />} />
                <Route
                  path={routing.game}
                  element={
                    <SensitiveRoute user={user}>
                      <PlayGame />
                    </SensitiveRoute>
                  }
                />
                <Route
                  path={routing.payment}
                  element={
                    <SensitiveRoute user={user}>
                      <Payments />
                    </SensitiveRoute>
                  }
                />
                <Route path={`${routing.gameDemo}`} element={<GameDemo />} />
                <Route path={`${routing.statistics}`} element={<Statistics />} />
                <Route
                  path={`${routing.paymentFailed}`}
                  element={<PaymentStatus status="failed" />}
                />
                <Route
                  path={`${routing.paymentSuccess}`}
                  element={<PaymentStatus status="success" />}
                />
                <Route
                  path={`${routing.paymentProcess}/:id/${routing.process}/:type`}
                  element={
                    <SensitiveRoute user={user}>
                      <ManualPayment />
                    </SensitiveRoute>
                  }
                />
                <Route
                  path={`${routing.paymentProcessConfirm}`}
                  element={
                    <SensitiveRoute user={user}>
                      <ManualPaymentConfirm />
                    </SensitiveRoute>
                  }
                />
                <Route
                  path={routing.notFound}
                  element={<NoMatch isUnderConstraction={location.pathname === routing.live} />}
                />
              </Routes>
            </Box>
            <Box className="footer" mt={20}>
              <Footer />
            </Box>
          </>
        </Box>
        <MainModal
          state={state}
          modalType={modalType}
          openModal={isModalOpened}
          handleClose={closeModal}
        />
        {isOpenDialogAlert ? (
          <Box>
            <DialogAlert
              content={contentText}
              severity={severity}
              isOpen={isOpenDialogAlert}
              onClose={handleOnClose}
            />
          </Box>
        ) : null}
      </Box>
    </ThemeProvider>
  );
}

export default App;
