import { Box, Button, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { routing } from 'constants/routing';
import { useGame } from 'hooks/playGames/useGame';
import noImage from 'assets/games/noImage.webp';
import { ModalState } from 'context/modal/modalContext';
import { useTranslation } from 'react-i18next';
import styles from './gameSession.module.scss';

interface GameSessionProps {
  onCloseGame?: () => void;
  onClose?: () => void;
  onContinueGame?: () => void;
  props?: ModalState;
}

export const GameSession = ({ onCloseGame, onContinueGame, onClose, props }: GameSessionProps) => {
  const { t } = useTranslation('common');
  const { state } = useLocation();
  const navigate = useNavigate();
  const { closeGame, continueGame } = useGame();

  const balanceType = { game: t('balanceTypeGame'), bonus: t('balanceTypeBonus') } as {
    [x: string]: string;
  };

  const handleCloseGame = async () => {
    if (props?.data?.sessionId) {
      const res = await closeGame(props.data.sessionId);
      if (res?.status === 200) {
        onCloseGame?.();
        onClose?.();
        if (props?.isOpenGame) {
          navigate(`${routing.game}`, {
            state: { ...state },
          });
        }
      }
    }
  };

  const handleContinuePlaying = async () => {
    const res = await continueGame();
    if (res?.status === 200) {
      const { data } = res;
      navigate(`${routing.game}`, {
        state: {
          launchURL: data.launchURL,
          sessionId: data.id,
          gameCounts: data.gameCounts,
          gameId: data.gameId,
          isLiked: data.isFavorite,
          isUnliked: data.isUnliked,
        },
      });
      onContinueGame?.();
      onClose?.();
    }
  };

  return (
    <Box>
      <Typography sx={{ mb: 2 }} align="center" variant="h6">
        {props?.data?.balance
          ? t('gameisOpened', { type: balanceType[props?.data?.balance] })
          : null}
      </Typography>
      <Box sx={{ m: 2, display: 'flex', alignItems: 'flex-start' }}>
        <img
          width="120"
          src={props?.data?.image ?? ''}
          onError={({ currentTarget }) => (currentTarget.src = noImage)}
          alt="gameImage"
        />
        <Typography sx={{ margin: '4px 6px', fontSize: '14px' }} align="right">
          {props?.data?.name ?? ''}
        </Typography>
      </Box>
      <Box className={styles.action}>
        <Button
          size="large"
          type="submit"
          fullWidth
          className={styles.close_btn}
          onClick={handleCloseGame}
          sx={{ minWidth: '180px' }}
        >
          {t('closeGame')}
        </Button>
        <Button
          size="large"
          type="submit"
          fullWidth
          className={styles.playing_btn}
          onClick={handleContinuePlaying}
        >
          {t('continueGame')}
        </Button>
      </Box>
    </Box>
  );
};
